import {Box, TextField, Typography, Button, Divider, CircularProgress, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { styled } from "@mui/system";
import { useAgentOnboarding } from '../../../contexts/AgentOnboardingContext';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AgentSecondStep = forwardRef((props, ref) => {
    const { agentData, updateStepData } = useAgentOnboarding();
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState(agentData.bankInfo || {
        bankName: '',
        accountNumber: '',
        accountName: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Expose saveData method via ref
    useImperativeHandle(ref, () => ({
        saveData: async () => {
            try {
                await handleSubmit();
                return true;
            } catch (error) {
                console.error('Error saving bank data:', error);
                return false;
            }
        }
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        
        setLoading(true);
        console.log('Saving bank data:', customerData);
        
        try {
            // Update the context with the form data
            updateStepData('step2', {
                bankInfo: customerData
            });
            
            setSnackbarMessage('Bank information saved successfully');
            setSnackbarSeverity('success');
            return true;
        } catch (error) {
            console.error('Error saving bank information:', error);
            setSnackbarMessage('Error saving bank information');
            setSnackbarSeverity('error');
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Box>
                <Typography variant='h4'>Agents Bank Details</Typography>
                <Typography variant='h5'>Provide your banking information.</Typography>
            </Box>

            <Divider/>
            {/* Bank Details Fields */}
            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Bank Name</TextFieldTitle>
                <TextField
                    name="bankName"
                    value={customerData.bankName}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Bank Name"
                    label="Bank Name"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Account Number</TextFieldTitle>
                <TextField
                    name="accountNumber"
                    value={customerData.accountNumber}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Account Number"
                    label="Account Number"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Account Name</TextFieldTitle>
                <TextField
                    name="accountName"
                    value={customerData.accountName}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Account Name"
                    label="Account Name"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert 
                    onClose={() => setSnackbarOpen(false)} 
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
});

export default AgentSecondStep;
