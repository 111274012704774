import React, {useEffect, useRef, useState, useCallback, useMemo, memo} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Divider, TextField, Typography, Checkbox, InputAdornment, CircularProgress, Snackbar, Alert } from "@mui/material";
import { useKeycloak } from '../../contexts/KeycloakContext';
import { styled } from "@mui/system";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { fetchOrganizations, patchOrganization } from "../../redux/organizationSlice";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const StoreSetupForm = React.memo(({ onSaveSuccess }) => {
    const dispatch = useDispatch();
    const pathInputRef = useRef(null);
    const { userInfo, isAuthenticated } = useKeycloak();
    const [isLoading, setIsLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [organizationId, setOrganizationId] = useState(null);

    useEffect(() => {
        if (isAuthenticated && userInfo) {
            setOrganizationId(userInfo.organization_id);
        }
    }, [isAuthenticated, userInfo]);

    const { organization, status, error } = useSelector((state) => state.organizations);
    const [formData, setFormData] = useState({
        id: '',
        storeName: '',
        storeUrl: '',
        storeLinkBase: 'https://pages.eventlah.com/',
        storeLinkPath: '',
        contactNumber: '',
        emailAddress: '',
        storeDescription: '',
    });

    const [operatingHours, setOperatingHours] = useState({
        Sunday: { isOpen: false, openTime: '', closeTime: '' },
        Monday: { isOpen: false, openTime: '', closeTime: '' },
        Tuesday: { isOpen: false, openTime: '', closeTime: '' },
        Wednesday: { isOpen: false, openTime: '', closeTime: '' },
        Thursday: { isOpen: false, openTime: '', closeTime: '' },
        Friday: { isOpen: false, openTime: '', closeTime: '' },
        Saturday: { isOpen: false, openTime: '', closeTime: '' },
    });

    useEffect(() => {
        dispatch(fetchOrganizations());
    }, [dispatch]);

    useEffect(() => {
        if (organization) {
            setFormData(prevData => ({
                ...prevData,
                ...organization,
                storeLinkPath: organization.storeUrl
                    ? organization.storeUrl.replace(prevData.storeLinkBase, '')
                    : '',
            }));

            if (organization.operatingHours) {
                setOperatingHours(prevHours => ({
                    ...prevHours,
                    ...organization.operatingHours
                }));
            }
        }
    }, [organization]);

    const handleInputChange = useCallback((name, value) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }, []);

    const handleOperatingHoursChange = useCallback((day, field, value) => {
        setOperatingHours(prevHours => {
            const updatedHours = { ...prevHours };
            if (field === 'isOpen') {
                updatedHours[day] = {
                    ...updatedHours[day],
                    isOpen: value,
                    openTime: value ? (updatedHours[day].openTime || '10:00') : '',
                    closeTime: value ? (updatedHours[day].closeTime || '22:00') : ''
                };
            } else {
                updatedHours[day] = {
                    ...updatedHours[day],
                    [field]: value
                };
            }
            return updatedHours;
        });
    }, []);

    const generateUrlFriendlyString = useCallback((str) => {
        return str.toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }, []);

    const handleStoreNameChange = useCallback((e) => {
        const newStoreName = e.target.value;
        const newStoreLinkPath = generateUrlFriendlyString(newStoreName);
        setFormData(prevData => ({
            ...prevData,
            storeName: newStoreName,
            storeLinkPath: newStoreLinkPath
        }));
    }, [generateUrlFriendlyString]);

    const handleStoreLinkPathChange = useCallback((e) => {
        const newPath = e.target.value.replace(/[^a-z0-9-]/g, '');
        handleInputChange('storeLinkPath', newPath);
    }, [handleInputChange]);

    const handleConfirm = useCallback(async () => {
        setIsLoading(true);
        try {
            if (!organizationId) {
                throw new Error('Organization ID is required');
            }

            const apiData = {
                ...formData,
                storeUrl: `${formData.storeLinkBase}${formData.storeLinkPath}`,
                operatingHours: operatingHours,
                organization_id: organizationId
            };

            const result = await dispatch(patchOrganization(apiData)).unwrap();

            setSnackbar({
                open: true,
                message: 'Store details saved successfully!',
                severity: 'success'
            });
            if (onSaveSuccess) {
                onSaveSuccess();
            }
        } catch (error) {
            console.error('Failed to save store details:', error);
            setSnackbar({
                open: true,
                message: 'Failed to save store details. Please try again.',
                severity: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, formData, operatingHours, onSaveSuccess]);

    const handleSnackbarClose = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(prev => ({ ...prev, open: false }));
    }, []);

    const isFormValid = useMemo(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return (
            formData.storeName?.trim() !== '' &&
            formData.storeLinkPath?.trim() !== '' &&
            formData.contactNumber?.trim() !== '' &&
            emailRegex.test(formData.emailAddress?.trim() || '')
        );
    }, [formData.storeName, formData.storeLinkPath, formData.contactNumber, formData.emailAddress]);

    const handleContactNumberChange = useCallback((e) => {
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        handleInputChange('contactNumber', numericValue);
    }, [handleInputChange]);

    const handleEmailChange = useCallback((e) => {
        handleInputChange('emailAddress', e.target.value);
    }, [handleInputChange]);

    const isValidEmail = useCallback((email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }, []);

    const focusPathInput = useCallback(() => {
        if (pathInputRef.current) {
            pathInputRef.current.focus();
        }
    }, []);

    const isTimeValid = useCallback((time) => {
        if (!time) return true;
        const [hours, minutes] = time.split(':');
        return parseInt(hours) >= 0 && parseInt(hours) < 24 && parseInt(minutes) >= 0 && parseInt(minutes) < 60;
    }, []);

    const formatTime = useCallback((time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }, []);

    const dayNames = useMemo(() => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], []);

    // Render logic remains largely unchanged
    // ...

    return (
        <Box>
            <Box component="form" noValidate autoComplete="off">

                {/*Store Logo*/}
                <Box sx={{ maxWidth: 400, margin: 'auto', padding: 2 }}>
                    <Box elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
                        <Box
                            sx={{
                                width: 100,
                                height: 100,
                                borderRadius: '50%',
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 'auto',
                                marginBottom: 2
                            }}
                        >
                            <ShoppingCartIcon sx={{ fontSize: 48, color: '#999' }} />
                        </Box>
                        <Typography variant="h6" gutterBottom>
                            Choose store logo
                        </Typography>
                        <Button variant="save">
                            Choose File
                        </Button>
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            Recommended: Square JPG, PNG, or GIF, at least 300px x 300px.
                        </Typography>
                    </Box>
                </Box>

                {/* Store Name */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Store Name <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        label="Enter store name"
                        placeholder="Store name"
                        value={formData.storeName || ''}
                        onChange={handleStoreNameChange}
                        fullWidth
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Your Store Link */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Your Store Link <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        label="Your Store Link"
                        value={formData.storeLinkPath || ''}
                        onChange={handleStoreLinkPathChange}
                        onClick={focusPathInput}
                        fullWidth
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {formData.storeLinkBase}
                                </InputAdornment>
                            ),
                        }}
                        inputRef={pathInputRef}
                        placeholder="path"
                    />
                </Box>

                <Divider />

                {/* Contact Number */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Contact Number <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        label="Enter a phone number to contact the store"
                        placeholder="0123456789"
                        value={formData.contactNumber || ''}
                        onChange={handleContactNumberChange}
                        fullWidth
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* ConfirmationEmail Address */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Email Address <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        label="Enter email contact for the store"
                        placeholder="you@example.com"
                        value={formData.emailAddress || ''}
                        onChange={handleEmailChange}
                        error={formData.emailAddress !== '' && !isValidEmail(formData.emailAddress)}
                        helperText={formData.emailAddress !== '' && !isValidEmail(formData.emailAddress) ? 'Invalid email format' : ''}
                        fullWidth
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Operating Hours */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>Operating Hours</TextFieldTitle>
                    <Box sx={{ width: '100%', paddingRight: { md: '50px', lg: '240px' } }}>
                        {dayNames.map((day) => (
                            <Box key={day} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Checkbox
                                    checked={operatingHours[day]?.isOpen || false}
                                    onChange={(e) => handleOperatingHoursChange(day, 'isOpen', e.target.checked)}
                                />
                                <Typography sx={{ width: '200px' }}>{day}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                    <TextField
                                        type="time"
                                        value={formatTime(operatingHours[day]?.openTime)}
                                        onChange={(e) => handleOperatingHoursChange(day, 'openTime', e.target.value)}
                                        disabled={!operatingHours[day]?.isOpen}
                                        sx={{ width: '150px' }}
                                        InputProps={{ inputProps: { step: 300 } }}
                                    />
                                    <Typography sx={{ mx: 1 }}>-</Typography>
                                    <TextField
                                        type="time"
                                        value={formatTime(operatingHours[day]?.closeTime)}
                                        onChange={(e) => handleOperatingHoursChange(day, 'closeTime', e.target.value)}
                                        disabled={!operatingHours[day]?.isOpen}
                                        sx={{ width: '150px' }}
                                        InputProps={{ inputProps: { step: 300 } }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Divider />

                {/* Store Description */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>Store Description</TextFieldTitle>
                    <TextField
                        label="Enter store description"
                        value={formData.storeDescription || ''}
                        onChange={(e) => handleInputChange('storeDescription', e.target.value)}
                        fullWidth
                        multiline
                        rows={7}
                        inputProps={{ maxLength: 250 }}
                        helperText={`${(formData.storeDescription || '').length}/250`}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                            paddingRight: { md: '50px', lg: '240px' }
                        }}
                    />
                </Box>
            </Box>

            {/* Button Group */}
            <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-start' }, mt: 5 }}>
                <Button
                    variant="save"
                    onClick={handleConfirm}
                    sx={{
                        textTransform: 'none',
                        height: '34px',
                        minWidth: '80px',
                        '&:disabled': {
                            color: '#FFF',
                        }
                    }}
                    disabled={!isFormValid || isLoading}
                >
                    {isLoading ? (
                        <>
                            <CircularProgress size={16} />
                            Loading...
                        </>
                    ) : (
                        formData.id ? "Edit" : "Save"
                    )}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
});

export default StoreSetupForm;
