import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Divider, Typography, Switch, Dialog, useMediaQuery, useTheme, CircularProgress, Alert } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaymentForm from "./OnlinePaymentForms/PaymentForm";
import { useKeycloak } from '../../../../../contexts/KeycloakContext';
import { authService } from '../../../../../services/auth.service';
import axios from '../../../../../utils/axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const paymentOptions = [
    {
        imgSrc: '/senangPay.png',
        imgAlt: 'senangPay',
        title: 'senangPay',
        code: 'senangpay',
        description: 'Popular in Malaysia',
    },
    // Add other payment options here with their codes
];

const OnlinePayment = ({ onPaymentMethodChange }) => {
    const [activeStates, setActiveStates] = useState(paymentOptions.map(() => false));
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { isAuthenticated, userInfo } = useKeycloak();
    const organizationId = userInfo?.organization_id;

    const hasActivePaymentMethod = useCallback(() => {
        return paymentMethods.length > 0 && activeStates.some(state => state);
    }, [paymentMethods, activeStates]);

    // Notify parent component when payment method status changes
    useEffect(() => {
        if (onPaymentMethodChange) {
            // Pass the full payment methods array to parent
            onPaymentMethodChange(paymentMethods);
        }
    }, [paymentMethods, onPaymentMethodChange]);

    const fetchPaymentMethods = useCallback(async () => {
        if (!organizationId || !isAuthenticated) {
            setLoading(false);
            return;
        }

        try {
            console.log('Rendering PaymentForm with existingMethod:', selectedPaymentMethod);
            const token = await authService.ensureValidToken();
            if (!token) {
                throw new Error('Authentication failed');
            }

            const response = await axios.get(`${API_BASE_URL}/merchant-payment-methods`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log('API Response:', response.data);

            if (response.data.code === 200 && response.data.data?.merchantPaymentMethods) {
                const methods = response.data.data.merchantPaymentMethods;
                console.log('Fetched payment methods:', methods);

                // Update active states based on existing payment methods
                const newActiveStates = paymentOptions.map(option => {
                    // Since the API response might have null codes, we need to handle that case
                    const existingMethod = methods.find(method => 
                        method.code === option.code || 
                        (method.title === option.title && !method.code)
                    );
                    return !!existingMethod;
                });

                setPaymentMethods(methods);
                setActiveStates(newActiveStates);

                // Notify parent of initial state
                if (onPaymentMethodChange) {
                    onPaymentMethodChange(methods);
                }
            } else {
                throw new Error('Invalid response format from server');
            }
        } catch (err) {
            console.error('Error fetching payment methods:', err);
            setError(err.response?.data?.message || err.message || 'Failed to load payment methods');
        } finally {
            setLoading(false);
        }
    }, [organizationId, isAuthenticated, onPaymentMethodChange]);

    useEffect(() => {
        fetchPaymentMethods();
    }, [fetchPaymentMethods]);

    const handleSave = async (formData, isEnabled) => {
        if (selectedIndex === null || !organizationId || !isAuthenticated) return;

        try {
            const token = await authService.ensureValidToken();
            if (!token) {
                throw new Error('Authentication failed');
            }

            const userInfo = await authService.getUserInfo();
            if (!userInfo?.organization_id) {
                throw new Error('Organization information not available');
            }

            const option = paymentOptions[selectedIndex];
            const payload = {
                ...formData,
                code: option.code,
                organization_id: organizationId,
                created_by: userInfo.id,
                environment_mode: formData.environmentMode || 'Test'
            };

            if (selectedPaymentMethod?.id) {
                await axios.put(
                    `${API_BASE_URL}/merchant-payment-methods?id=${selectedPaymentMethod.id}`,
                    payload,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
            } else {
                await axios.post(
                    `${API_BASE_URL}/merchant-payment-methods`,
                    payload,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
            }

            // After successful save, refresh the payment methods
            await fetchPaymentMethods();
            handleDialogClose();

        } catch (err) {
            console.error('Error updating payment method:', err);
            throw err; // Let the PaymentForm handle the error
        }
    };

    const toggleActive = async (index) => {
        if (!isAuthenticated || !organizationId) return;

        try {
            setLoading(true);
            const option = paymentOptions[index];
            const existingMethod = paymentMethods.find(method => 
                method.code === option.code || 
                (method.title === option.title && !method.code)
            );

            const token = await authService.ensureValidToken();
            if (!token) {
                throw new Error('Authentication failed');
            }

            const userInfo = await authService.getUserInfo();
            if (!userInfo?.organization_id) {
                throw new Error('Organization information not available');
            }

            // Update local state before API call to prevent UI flicker
            setActiveStates(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = !newStates[index];
                return newStates;
            });

            if (existingMethod) {
                // Update existing method's status
                const payload = {
                    ...existingMethod,
                    is_active: !activeStates[index]
                };

                const response = await axios.put(
                    `${API_BASE_URL}/merchant-payment-methods?id=${existingMethod.id}`,
                    payload,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );

                // Update payment methods state locally
                if (response.data.code === 200 && response.data.data) {
                    setPaymentMethods(prev => 
                        prev.map(method => 
                            method.id === existingMethod.id 
                                ? { ...method, is_active: !activeStates[index] }
                                : method
                        )
                    );
                }
            } else if (!activeStates[index]) {
                // Create new payment method when activating
                const payload = {
                    code: option.code,
                    title: option.title,
                    description: option.description,
                    organization_id: organizationId,
                    created_by: userInfo.id,
                    environment_mode: 'Test',
                    merchant_id: '',  // These will be updated later through the form
                    secret_key: '',
                    hash_type: 'SHA256',
                    is_active: true
                };

                const response = await axios.post(
                    `${API_BASE_URL}/merchant-payment-methods`,
                    payload,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );

                // Update payment methods state locally
                if (response.data.code === 200 && response.data.data) {
                    setPaymentMethods(prev => [...prev, response.data.data]);
                }
            }
        } catch (err) {
            console.error('Error toggling payment method:', err);
            setError(err.response?.data?.message || err.message || 'Failed to update payment method');
            // Revert the local state if there was an error
            setActiveStates(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = !newStates[index];
                return newStates;
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDialogOpen = (index) => {
        console.log('handleDialogOpen called with index:', index);
        setSelectedIndex(index);
        const option = paymentOptions[index];
        
        // Find existing method by code or title if code is null
        const existingMethod = paymentMethods.find(method => 
            method.code === option.code || 
            (method.title === option.title && !method.code)
        );

        console.log('Opening dialog with existing method:', existingMethod);
        setSelectedPaymentMethod(existingMethod);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedIndex(null);
        setSelectedPaymentMethod(null);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ color: 'error.main', p: 2 }}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
                <Button variant="outlined" onClick={fetchPaymentMethods}>
                    Retry
                </Button>
            </Box>
        );
    }

    if (!isAuthenticated || !organizationId) {
        return (
            <Box sx={{ color: 'error.main', p: 2 }}>
                <Alert severity="error">
                    Please log in to manage payment methods
                </Alert>
            </Box>
        );
    }

    return (
        <Box>
            {paymentOptions.map((option, index) => (
                <Box key={index}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        padding: isMobile ? '10px 0' : '5px 0'
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 1 : 0 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: '6px', cursor: 'pointer' }} onClick={() => handleDialogOpen(index)}>
                                {dialogOpen && selectedIndex === index ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </Box>
                            <Box component="img" src={option.imgSrc} alt={option.imgAlt} sx={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                            <Box sx={{ marginLeft: '5px' }}>
                                <Typography sx={{ fontSize: isMobile ? '13px' : '14px', fontWeight: '700' }}>{option.title}</Typography>
                                <Typography sx={{ fontSize: isMobile ? '10px' : '11px', fontWeight: '500' }}>{option.description}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '3px',
                            width: isMobile ? '100%' : 'auto'
                        }}>
                            <Switch
                                checked={activeStates[index]}
                                onChange={() => toggleActive(index)}
                                size="small"
                                disabled={loading}
                            />
                            {activeStates[index] ? (
                                <CheckCircleOutlineIcon
                                    sx={{ color: 'green', fontSize: isMobile ? '18px' : '20px', fontWeight: '900', cursor: loading ? 'default' : 'pointer' }}
                                    onClick={() => !loading && toggleActive(index)}
                                />
                            ) : (
                                <CancelIcon
                                    sx={{ color: 'red', fontSize: isMobile ? '18px' : '20px', fontWeight: '900', cursor: loading ? 'default' : 'pointer' }}
                                    onClick={() => !loading && toggleActive(index)}
                                />
                            )}
                            <Typography sx={{ fontSize: isMobile ? '12px' : '14px', fontWeight: '500' }}>
                                {activeStates[index] ? 'Active' : 'Inactive'}
                            </Typography>
                            <Button
                                variant='cancel'
                                sx={{
                                    minWidth: '1px',
                                    width: '20px',
                                    height: '33px',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    marginLeft: 'auto'
                                }}
                                onClick={() => handleDialogOpen(index)}
                                disabled={loading}
                            >
                                <Typography sx={{ marginBottom: '7px' }}>...</Typography>
                            </Button>
                        </Box>
                    </Box>
                    {index < paymentOptions.length - 1 && <Divider />}
                </Box>
            ))}

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="sm"
            >
                {selectedIndex !== null && (
                    <PaymentForm
                        header={paymentOptions[selectedIndex].title}
                        subHeader={paymentOptions[selectedIndex].description}
                        onSave={handleSave}
                        onCancel={handleDialogClose}
                        imgSrc={paymentOptions[selectedIndex].imgSrc}
                        imgAlt={paymentOptions[selectedIndex].imgAlt}
                        initialEnabled={activeStates[selectedIndex]}
                        existingMethod={selectedPaymentMethod}
                        loading={loading}
                    />
                )}
            </Dialog>
            {!hasActivePaymentMethod() && (
                <Box sx={{ mt: 2 }}>
                    <Alert severity="warning">
                        Please select and configure a payment method to proceed
                    </Alert>
                </Box>
            )}
        </Box>
    );
};

export default OnlinePayment;