import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    List, 
    ListItem, 
    LinearProgress, 
    Link,
    IconButton,
    Tooltip,
    Popover,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import { authService } from '../../services/auth.service';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(1),
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        transform: 'translateX(8px)',
        '& .MuiSvgIcon-root.arrow': {
            opacity: 1,
            transform: 'translateX(0)',
        },
    },
    cursor: 'pointer',
    position: 'relative',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
        textDecoration: 'none',
    },
}));

const OnboardingChecklist = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeDescription, setActiveDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [onboardingData, setOnboardingData] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [token, setToken] = useState(null);

    // First useEffect: Handle authentication and user info
    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const validToken = await authService.ensureValidToken();
                if (!validToken) {
                    setError('Authentication failed');
                    return;
                }
                setToken(validToken);

                const userInfo = await authService.getUserInfo();
                if (!userInfo?.email) {
                    setError('User information not available');
                    return;
                }
                setUserEmail(userInfo.email);
            } catch (err) {
                console.error('Auth initialization error:', err);
                setError('Failed to initialize authentication');
            }
        };

        initializeAuth();
    }, []); // Run once on mount

    // Second useEffect: Fetch onboarding data when auth is ready
    useEffect(() => {
        const fetchOnboardingData = async () => {
            if (!token || !userEmail) {
                return; // Wait for auth to be ready
            }

            if (!API_BASE_URL) {
                console.error('API_BASE_URL is not defined');
                setError('API configuration error');
                return;
            }

            setLoading(true);
            try {
                const url = `${API_BASE_URL}/account-onboardings?email=${userEmail}`;
                console.log('Making API call to:', url);

                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                console.log('Onboarding Data:', response.data.data.onboardingRecord);
                setOnboardingData(response.data.data.onboardingRecord);
                setError(null);
            } catch (err) {
                console.error('Error fetching onboarding data:', err);
                console.error('Error response:', err.response);
                console.error('Error request:', err.request);
                console.error('Error config:', err.config);
                setError(err.response?.data?.message || 'Failed to fetch onboarding data');
            } finally {
                setLoading(false);
            }
        };

        fetchOnboardingData();
    }, [token, userEmail]); // Only run when token or userEmail changes

    const handleInfoClick = (event, description) => {
        event.preventDefault();
        event.stopPropagation();
        setActiveDescription(description);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const checklistItems = [
        {
            id: 'first',
            label: 'Organization Details',
            href: '/first',
            description: 'Register your organization details and business information',
        },
        {
            id: 'second',
            label: 'Document Verification',
            href: '/second',
            description: 'Upload and verify required business documents',
        },
        {
            id: 'third',
            label: 'Store Setup',
            href: '/third',
            description: 'Configure your store settings and preferences',
        },
        {
            id: 'fourth',
            label: 'Billing Information',
            href: '/fourth',
            description: 'Set up your billing and subscription details',
        },
        {
            id: 'fifth',
            label: 'Event Setup',
            href: '/fifth',
            description: 'Add and configure your events',
        },
        {
            id: 'sixth',
            label: 'Payment Setup',
            href: '/sixth',
            description: 'Set up your payment methods and accounts',
        },
        {
            id: 'seventh',
            label: 'Terms & Policies',
            href: '/seventh',
            description: 'Review and accept terms and policies',
        }
    ].map(item => ({
        ...item,
        completed: onboardingData?.completed_steps?.includes(item.id) || false
    }));

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={2}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    // Calculate progress
    const progress = (checklistItems.filter(item => item.completed).length / checklistItems.length) * 100;

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Typography variant='h6' gutterBottom>
                Onboarding Steps
            </Typography>

            <Box sx={{ mb: 2 }}>
                <LinearProgress variant='determinate' value={progress} sx={{ height: 8, borderRadius: 4 }} />
                <Typography variant='body2' color='text.secondary' sx={{ mt: 1 }}>
                    {Math.round(progress)}% Complete
                </Typography>
            </Box>

            <List sx={{ '& .MuiListItem-root': { minHeight: '48px' } }}>
                {checklistItems.map(item => (
                    <StyledLink href={item.href} key={item.id}>
                        <StyledListItem
                            disablePadding
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                py: 1.5,
                                px: 1.5,
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                minHeight: '48px'
                            }}
                        >
                            {item.completed ? (
                                <CheckCircleIcon color='success' sx={{ mr: 1.5, flexShrink: 0, fontSize: 20 }} />
                            ) : (
                                <CancelIcon color='disabled' sx={{ mr: 1.5, flexShrink: 0, fontSize: 20 }} />
                            )}
                            
                            <Typography 
                                sx={{ 
                                    fontWeight: 500, 
                                    flex: 1,
                                    fontSize: '0.875rem',
                                    wordBreak: 'break-word',
                                    lineHeight: 1.4
                                }}
                            >
                                {item.label}
                            </Typography>

                            <IconButton 
                                size="small" 
                                onClick={(e) => handleInfoClick(e, item.description)}
                                sx={{ 
                                    mr: 0.5,
                                    padding: 0.5
                                }}
                            >
                                <InfoOutlinedIcon sx={{ fontSize: 18 }} />
                            </IconButton>

                            <ArrowForwardIosIcon
                                className='arrow'
                                sx={{
                                    opacity: 0,
                                    transform: 'translateX(-8px)',
                                    transition: 'all 0.2s ease-in-out',
                                    fontSize: 14,
                                    color: 'primary.main',
                                    flexShrink: 0,
                                }}
                            />
                        </StyledListItem>
                    </StyledLink>
                ))}
            </List>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        p: 2,
                        maxWidth: 300,
                        boxShadow: (theme) => theme.shadows[3],
                    }
                }}
            >
                <Typography variant="body2">
                    {activeDescription}
                </Typography>
            </Popover>
        </Box>
    );
};

export default OnboardingChecklist;
