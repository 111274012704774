import { useState, useRef, useEffect } from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AgentOnboardingProvider, useAgentOnboarding } from '../../../contexts/AgentOnboardingContext';
import { authService } from '../../../services/auth.service';
import { agentOnboardingService } from '../../../services/agentOnboardingService';
import AgentFirstStep from "./AgentFirstStep";
import AgentSecondStep from "./AgentSecondStep";
import AgentThirdStep from "./AgentThirdStep";
import AgentFourthStep from "./AgentFourthStep";

const steps = ['Personal Information', 'Bank Details', 'Documents', 'Review'];

const AgentOnboardingContent = () => {
    const [searchParams] = useSearchParams();
    const stepParam = searchParams.get('step');
    const [activeStep, setActiveStep] = useState(() => {
        if (stepParam) {
            const stepNumber = parseInt(stepParam.replace('step', '')) - 1;
            return isNaN(stepNumber) ? 0 : stepNumber;
        }
        return 0;
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { isStepComplete, agentData } = useAgentOnboarding();

    // Refs for step components
    const firstStepRef = useRef();
    const secondStepRef = useRef();
    const thirdStepRef = useRef();

    // Set initial step based on API data
    useEffect(() => {
        if (agentData.onboardingRecord?.current_step) {
            const stepMap = {
                'step1': 0,
                'step2': 1,
                'step3': 2,
                'step4': 3
            };
            // Only update if no step parameter is provided
            if (!stepParam) {
                setActiveStep(stepMap[agentData.onboardingRecord.current_step] || 0);
            }
        }
    }, [agentData.onboardingRecord, stepParam]);

    const handleNext = async () => {
        setLoading(true);
        try {
            console.log('Attempting to save step:', activeStep);
            let success = false;
            switch (activeStep) {
                case 0:
                    console.log('Calling saveData on firstStepRef');
                    success = await firstStepRef.current?.saveData();
                    break;
                case 1:
                    console.log('Calling saveData on secondStepRef');
                    success = await secondStepRef.current?.saveData();
                    break;
                case 2:
                    console.log('Calling saveData on thirdStepRef');
                    success = await thirdStepRef.current?.saveData();
                    break;
                default:
                    success = true;
            }

            console.log('Save result:', success);

            if (success) {
                // Map step number to step name for API
                const stepNames = ['step1', 'step2', 'step3', 'step4'];
                const currentStep = stepNames[activeStep];
                const nextStep = stepNames[activeStep + 1];

                if (activeStep < steps.length - 1) {
                    // Update URL with next step
                    const params = new URLSearchParams(searchParams.toString());
                    params.set('step', nextStep);
                    navigate(`/agent-onboarding?${params.toString()}`);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    // Final step completion
                    if (agentData.onboardingRecord) {
                        await agentOnboardingService.updateOnboardingStep(
                            agentData.onboardingRecord.user_id,
                            'completed',
                            ['step1', 'step2', 'step3', 'step4']
                        );
                    }
                    navigate('/finish');
                }
            }
        } catch (error) {
            console.error('Error saving step data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            const stepNames = ['step1', 'step2', 'step3', 'step4'];
            const prevStep = stepNames[activeStep - 1];
            const params = new URLSearchParams(searchParams.toString());
            params.set('step', prevStep);
            navigate(`/agent-onboarding?${params.toString()}`);
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    return (
        <Box sx={{mt:5}}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: '32px', margin: '32px', borderRadius: '12px' }}>
                {/* Step 1 Content */}
                {activeStep === 0 && <AgentFirstStep ref={firstStepRef} />}

                {/* Step 2 Content */}
                {activeStep === 1 && <AgentSecondStep ref={secondStepRef} />}

                {/* Step 3 Content */}
                {activeStep === 2 && <AgentThirdStep ref={thirdStepRef} />}

                {/* Step 4 Content */}
                {activeStep === 3 && <AgentFourthStep />}

                <Divider sx={{ my: 3 }} />

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button 
                        onClick={handleNext}
                        disabled={loading}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

const AgentOnboarding = () => {
    return (
        <AgentOnboardingProvider>
            <AgentOnboardingContent />
        </AgentOnboardingProvider>
    );
};

export default AgentOnboarding;
