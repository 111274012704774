import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authService } from '../services/auth.service';
import { jwtDecode } from 'jwt-decode';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api'

export const fetchOnboardingRecords = createAsyncThunk(
    'onboarding/fetchOnboardingRecords',
    async (status, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get(`${API_BASE_URL}/account-onboardings?email=${status}`, { headers });
            return { status, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createOnboardingRecord = createAsyncThunk(
    'onboarding/createOnboardingRecord',
    async (onboardingData, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${API_BASE_URL}/account-onboardings`, onboardingData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateOnboardingRecord = createAsyncThunk(
    'onboarding/updateOnboardingRecord',
    async ({ email, onboardingData }, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            
            const response = await axios.patch(
                `${API_BASE_URL}/account-onboardings?email=${encodeURIComponent(email)}`,
                onboardingData,
                { headers }
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState: {
        records: [],
        currentStep: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOnboardingRecords.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOnboardingRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currentStep = action.payload?.data?.data?.onboardingRecord?.current_step;
                state.records = action.payload.data;
            })
            .addCase(fetchOnboardingRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch onboarding records';
            })
            .addCase(createOnboardingRecord.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createOnboardingRecord.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.records.push(action.payload);
            })
            .addCase(createOnboardingRecord.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create onboarding record';
            })
            .addCase(updateOnboardingRecord.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateOnboardingRecord.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(updateOnboardingRecord.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update onboarding record';
            });
    },
});

export const { setCurrentStep } = onboardingSlice.actions;
export default onboardingSlice.reducer;
