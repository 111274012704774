import { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Switch,
    Button,
    Divider,
    styled,
    TextField,
    MenuItem,
    FormControl,
    InputAdornment,
    CircularProgress,
    Alert,
} from '@mui/material';
import { authService } from '../../../../services/auth.service';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const SettingRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: '16px 0',
    width: '100%',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const ConstrainedContainer = styled(Box)({
    maxWidth: '320px',
    width: '100%'
});

const FieldContainer = styled(Box)({
    width: '100%'
});

const PayoutSettings = () => {
    const [settings, setSettings] = useState({
        payout_frequency: 'monthly',
        minimum_payout_amount: '100',
        payment_method: 'bank_transfer'
    });

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [organizationId, setOrganizationId] = useState(null);

    useEffect(() => {
        const initializeSettings = async () => {
            try {
                const userInfo = await authService.getUserInfo();
                if (userInfo?.organization_id) {
                    setOrganizationId(userInfo.organization_id);
                } else {
                    throw new Error('Organization ID not found');
                }
            } catch (error) {
                console.error('Error getting user info:', error);
                setError('Failed to initialize settings: ' + error.message);
                setLoading(false);
            }
        };

        initializeSettings();
    }, []);

    useEffect(() => {
        const fetchSettings = async () => {
            if (!organizationId) return;
            
            try {
                await authService.ensureValidToken();
                const response = await fetch(`${BASE_API_URL}/merchant-agent-settings?organization_id=${organizationId}`, {
                    headers: {
                        'Authorization': `Bearer ${authService.getAccessToken()}`
                    },
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch payout settings');
                }
                
                const data = await response.json();
                if (data) {
                    setSettings({
                        payout_frequency: data.payout_frequency || 'monthly',
                        minimum_payout_amount: data.minimum_payout_amount?.toString() || '100',
                        payment_method: data.payment_methods?.[0] || 'bank_transfer'
                    });
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, [organizationId]);

    const handleChange = (field) => (event) => {
        setSettings(prev => ({
            ...prev,
            [field]: event.target.value
        }));
        setHasChanges(true);
    };

    const handleSave = async () => {
        if (!organizationId) {
            setError('Organization ID not found');
            return;
        }

        setSaving(true);
        setError(null);
        
        try {
            await authService.ensureValidToken();
            const response = await fetch(`${BASE_API_URL}/merchant-agent-settings`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organization_id: organizationId,
                    payout_frequency: settings.payout_frequency,
                    minimum_payout_amount: parseFloat(settings.minimum_payout_amount),
                    payment_methods: [settings.payment_method], // Convert single method to array for API
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save payout settings');
            }

            setHasChanges(false);
        } catch (err) {
            setError(err.message);
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}
            
            <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6" fontWeight={600} gutterBottom>
                    Payout Settings
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Configure how and when agents receive their payments
                </Typography>
            </Box>

            <Divider />

            <SettingRow>
                <FieldContainer>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                        Payout Frequency
                    </Typography>
                    <ConstrainedContainer>
                        <FormControl fullWidth>
                            <TextField
                                select
                                value={settings.payout_frequency}
                                onChange={handleChange('payout_frequency')}
                                variant="outlined"
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="weekly">Weekly</MenuItem>
                                <MenuItem value="biweekly">Bi-weekly</MenuItem>
                                <MenuItem value="monthly">Monthly</MenuItem>
                                <MenuItem value="quarterly">Quarterly</MenuItem>
                            </TextField>
                        </FormControl>
                    </ConstrainedContainer>
                </FieldContainer>
            </SettingRow>

            <Divider />

            <SettingRow>
                <FieldContainer>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                        Minimum Payout Amount
                    </Typography>
                    <ConstrainedContainer>
                        <FormControl fullWidth>
                            <TextField
                                value={settings.minimum_payout_amount}
                                onChange={handleChange('minimum_payout_amount')}
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                }}
                            />
                        </FormControl>
                    </ConstrainedContainer>
                </FieldContainer>
            </SettingRow>

            <Divider />

            <SettingRow>
                <FieldContainer>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                        Payment Method
                    </Typography>
                    <ConstrainedContainer>
                        <FormControl fullWidth>
                            <TextField
                                select
                                value={settings.payment_method}
                                onChange={handleChange('payment_method')}
                                variant="outlined"
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
                            </TextField>
                        </FormControl>
                    </ConstrainedContainer>
                </FieldContainer>
            </SettingRow>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={!hasChanges || saving}
                    startIcon={saving && <CircularProgress size={20} color="inherit" />}
                >
                    {saving ? 'Saving...' : 'Save Changes'}
                </Button>
            </Box>
        </Box>
    );
};

export default PayoutSettings;