import { useState, Fragment, useEffect } from 'react';
import {
    Box,
    Typography,
    Switch,
    Button,
    Divider,
    styled,
    IconButton,
    Tooltip,
    Collapse,
    Link,
    Snackbar,
    Alert
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import { authService } from '../../../../services/auth.service';

const SettingRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },
}));

const SettingInfo = styled(Box)(({ theme }) => ({
    flex: 1,
    marginRight: theme.spacing(2),
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const LinkItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
}));

const OnboardingSettings = () => {
    const [settings, setSettings] = useState({
        id: null,
        requireId: false,
        enableAutoApproval: false,
        requireDocuments: false,
        requireTraining: false
    });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [hasChanges, setHasChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const [organizationId, setOrganizationId] = useState(null);

    const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const getUserInfo = async () => {
            const userInfo = await authService.getUserInfo();
            if (userInfo) {
                setOrganizationId(userInfo.organization_id);
            }
        };
        getUserInfo();
    }, []);

    useEffect(() => {
        if (organizationId) {
            fetchSettings();
        }
    }, [organizationId]);

    const fetchSettings = async () => {
        if (!organizationId) return;
        
        try {
            await authService.ensureValidToken();
            const response = await axios.get(`${BASE_API_URL}/merchant-agent-settings?organization_id=${organizationId}`, {
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`
                }
            });
            if (response.data) {
                const { id, onboarding_requirements } = response.data;
                setSettings({
                    id,
                    requireId: onboarding_requirements.requireId || false,
                    enableAutoApproval: onboarding_requirements.enableAutoApproval || false,
                    requireDocuments: onboarding_requirements.requireDocuments || false,
                    requireTraining: onboarding_requirements.requireTraining || false
                });
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
            setSnackbar({
                open: true,
                message: 'Failed to load settings',
                severity: 'error'
            });
        }
    };

    const handleSettingChange = (setting) => (event) => {
        setSettings(prev => ({
            ...prev,
            [setting]: event.target.checked
        }));
        setHasChanges(true);
    };

    const handleSave = async () => {
        if (!organizationId) return;
        setLoading(true);

        try {
            await authService.ensureValidToken();
            await axios.put(`${BASE_API_URL}/merchant-agent-settings`, {
                id: settings.id,
                organization_id: organizationId,
                onboarding_requirements: settings
            }, {
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`
                }
            });
            
            if (true) {
                setSnackbar({
                    open: true,
                    message: 'Settings saved successfully',
                    severity: 'success'
                });
                setHasChanges(false);
            }
        } catch (error) {
            console.error('Error saving settings:', error);
            setSnackbar({
                open: true,
                message: 'Failed to save settings',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const trainingLinks = [
        {
            title: 'Product Knowledge Training',
            url: 'https://example.com/product-training',
            description: 'Complete core product features and capabilities training'
        },
        {
            title: 'Compliance Guidelines',
            url: 'https://example.com/compliance',
            description: 'Review regulatory requirements and compliance procedures'
        },
        {
            title: 'Best Practices Guide',
            url: 'https://example.com/best-practices',
            description: 'Learn industry best practices and recommended approaches'
        }
    ];

    const settingsConfig = [
        // {
        //     key: 'requireId',
        //     title: 'Require ID verification',
        //     description: 'Agents must verify their identity using a government-issued ID before registration',
        //     tooltip: 'We use secure verification providers to validate government IDs'
        // },
        {
            key: 'enableAutoApproval',
            title: 'Enable automatic approval',
            description: 'Automatically approve new agents that meet all requirements',
            tooltip: 'Agents will still need to complete all required verification steps'
        },
        {
            key: 'requireDocuments',
            title: 'Require document submission',
            description: 'Agents must upload necessary documentation before completing registration',
            tooltip: 'Include business licenses, certifications, and other relevant documents'
        },
        // {
        //     key: 'requireTraining',
        //     title: 'Require training completion',
        //     description: 'Agents must complete mandatory training modules before activation',
        //     tooltip: 'Training includes product knowledge, compliance, and best practices',
        //     extraContent: (
        //         <Collapse in={settings.requireTraining}>
        //             <Box sx={{ mt: 2 }}>
        //                 {trainingLinks.map((link, index) => (
        //                     <LinkItem key={index}>
        //                         <Box>
        //                             <Link
        //                                 href={link.url}
        //                                 target="_blank"
        //                                 rel="noopener noreferrer"
        //                                 sx={{
        //                                     display: 'flex',
        //                                     alignItems: 'center',
        //                                     gap: 0.5,
        //                                     color: 'orange',
        //                                     '&:hover': {
        //                                         textDecoration: 'underline'
        //                                     }
        //                                 }}
        //                             >
        //                                 {link.title}
        //                                 <OpenInNewIcon sx={{ fontSize: 16 }} />
        //                             </Link>
        //                             <Typography variant="caption" color="text.secondary" display="block">
        //                                 {link.description}
        //                             </Typography>
        //                         </Box>
        //                     </LinkItem>
        //                 ))}
        //             </Box>
        //         </Collapse>
        //     )
        // },
    ];

    return (
        <Box>
            <Box>
                <Typography variant="h6" fontWeight={600} gutterBottom>
                    Onboarding Settings
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Set requirements for new agent registration
                </Typography>
            </Box>

            <Divider />

            <Box>
                {settingsConfig.map((setting, index) => (
                    <Fragment key={setting.key}>
                        <SettingRow>
                            <SettingInfo>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="subtitle1" fontWeight={500}>
                                        {setting.title}
                                    </Typography>
                                    <Tooltip title={setting.tooltip}>
                                        <IconButton size="small">
                                            <HelpOutlineIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {setting.description}
                                </Typography>
                            </SettingInfo>
                            <StyledSwitch
                                checked={settings[setting.key]}
                                onChange={handleSettingChange(setting.key)}
                            />
                        </SettingRow>
                        {setting.extraContent}
                        {index < settingsConfig.length - 1 && <Divider />}
                    </Fragment>
                ))}
            </Box>

            <Divider />

            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!hasChanges || loading}
                    onClick={handleSave}
                >
                    {loading ? 'Saving...' : 'Save Settings'}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default OnboardingSettings;