import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Plus } from 'lucide-react';
import OnboardingSettings from './OnboardingSettings';
import CommissionSettings from './CommissionSettings';
import PayoutSettings from './PayoutSettings';
import MaterialSettings from './MaterialSettings';
import PermissionGate from 'components/shared/PermissionGate';
import { useOrganizationRoles } from 'components/shared/useOrganizationRoles';
import { useKeycloak } from 'contexts/KeycloakContext';
import { authService } from 'services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AgentSetup = () => {
    const [value, setValue] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailList, setEmailList] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const organizationRoles = useOrganizationRoles();
    const isOrgAdmin = organizationRoles?.includes('org_admin');
    const { userInfo } = useKeycloak();
    const organizationId = userInfo?.organization_id;

    const handleAddEmail = () => {
        if (validateEmail(email)) {
            if (!emailList.includes(email)) {
                setEmailList([...emailList, email]);
                setEmail('');
                setError('');
            } else {
                setError('Email already added to the list');
            }
        }
    };

    const handleRemoveEmail = (index) => {
        const newList = [...emailList];
        newList.splice(index, 1);
        setEmailList(newList);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleAddAgents = async () => {
        if (!organizationId) {
            setError('Organization ID not found. Please try logging out and back in.');
            return;
        }

        try {
            setLoading(true);
            setError('');
            
            const token = await authService.ensureValidToken();
            
            // Process each email sequentially
            for (const email of emailList) {
                try {
                    const response = await fetch(`${API_BASE_URL}/user-agents?merchantAddAgent=true`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email,
                            organizationId
                        })
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.description || 'Failed to add agent');
                    }
                } catch (error) {
                    console.error(`Error adding agent with email ${email}:`, error);
                    setError(prev => prev ? `${prev}\n${email}: ${error.message}` : `${email}: ${error.message}`);
                }
            }

            if (!error) {
                setIsModalOpen(false);
                setEmail('');
                setEmailList([]);
            }
        } catch (error) {
            console.error('Error in handleAddAgents:', error);
            setError('Failed to add agents. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        { label: 'Onboarding Settings', featureCode: 'AGTS_READ', component: OnboardingSettings },
        { label: 'Commission Settings', featureCode: 'AGTS_READ1', component: CommissionSettings },
        { label: 'Payout Settings', featureCode: 'AGTS_READ2', component: PayoutSettings },
        { label: 'Material Settings', featureCode: 'AGTS_READ3', component: MaterialSettings }
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Agent Setup</Typography>
                        <Typography variant='h5'>Manage and configure your agent setup settings.</Typography>
                    </Box>
                    <Box
                        sx={{
                            ml: 'auto',
                            mr: '32px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {isOrgAdmin ? (
                                <Button 
                                    variant="contained" 
                                    onClick={() => setIsModalOpen(true)}
                                    startIcon={<Plus />}
                                >
                                    Add Agent(s)
                                </Button>
                        ) : (
                            <PermissionGate featureCode='CRTAGT' fallback={null}>
                                <Button 
                                    variant="contained" 
                                    onClick={() => setIsModalOpen(true)}
                                    startIcon={<Plus />}
                                >
                                    Add Agent(s)
                                </Button>
                            </PermissionGate>
                        )}
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs 
                        value={value} 
                        onChange={handleTabChange} 
                        aria-label='service tabs'
                    >
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} />
                        ))}
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>
                    {isOrgAdmin ? (
                        tabs.map((tab, index) => (
                            value === index && <tab.component key={index} />
                        ))
                    ) : (
                        tabs.map((tab, index) => (
                            value === index && (
                                <PermissionGate
                                    key={index}
                                    featureCode={tab.featureCode}
                                    fallback={null}
                                >
                                    <tab.component />
                                </PermissionGate>
                            )
                        ))
                    )}
                </Box>
            </Box>

            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Add Agent(s)</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Agent Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={email && !validateEmail(email)}
                            helperText={email && !validateEmail(email) ? 'Invalid email format' : ''}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && validateEmail(email)) {
                                    handleAddEmail();
                                }
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleAddEmail}
                            disabled={!validateEmail(email)}
                        >
                            Add Email
                        </Button>
                    </Box>
                    
                    {emailList.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Emails to add:
                            </Typography>
                            {emailList.map((email, index) => (
                                <Box 
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 1,
                                        borderBottom: '1px solid',
                                        borderColor: 'divider'
                                    }}
                                >
                                    <Typography>{email}</Typography>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => handleRemoveEmail(index)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={handleAddAgents}
                        disabled={emailList.length === 0 || loading}
                    >
                        {loading ? 'Adding...' : 'Add Agents'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AgentSetup;
