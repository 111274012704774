import axios from 'axios';
import { authService } from '../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/register`, userData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return {
      data: response.data,
      status: response.status
    };
  } catch (error) {
    if (error.response) {
      return {
        error: error.response.data?.error || 'Registration failed',
        status: error.response.status
      };
    } else if (error.request) {
      return {
        error: 'No response from server',
        status: 500
      };
    } else {
      return {
        error: error.message || 'Registration failed',
        status: 500
      };
    }
  }
};

export const registerAgent = async (agentData) => {
  try {
    // Get valid token for existing users
    let headers = {
      'Content-Type': 'application/json'
    };

    if (agentData.existingUser) {
      const token = await authService.ensureValidToken();
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await axios.post(`${API_BASE_URL}/register-agent`, {
      ...agentData,
      roles: ['eventlah-agent'],
      metadata: { 
        ...agentData.metadata,
        agentId: agentData.agentId 
      }
    }, {
      headers,
      withCredentials: true
    });

    return {
      data: response.data,
      status: response.status
    };
  } catch (error) {
    if (error.response) {
      return {
        error: error.response.data?.error || 'Agent registration failed',
        status: error.response.status
      };
    } else if (error.request) {
      return {
        error: 'No response from server',
        status: 500
      };
    } else {
      return {
        error: error.message || 'Agent registration failed',
        status: 500
      };
    }
  }
};
