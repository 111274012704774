import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrganizationProfiles } from '../redux/organizationProfilesSlice';
import { Link } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import axios from 'axios';
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Collapse,
    useMediaQuery,
    useTheme,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Chip,
    Button,
    drawerClasses,
    Divider,
} from '@mui/material';
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Receipt as ReceiptIcon,
    ExitToApp as ExitToAppIcon,
    ExpandLess,
    ExpandMore,
    AdminPanelSettings as AdminIcon,
    Storefront as StorefrontIcon,
    Assignment as AssignmentIcon,
    EventNote as EventNoteIcon,
    CalendarToday as CalendarTodayIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Payments as PaymentsIcon,
    AttachMoney as AttachMoneyIcon,
    Check as CheckIcon,
    RoomPreferences as RoomPreferencesIcon,
    PersonAddAlt1 as PersonAddAlt1Icon,
    Settings as SettingsIcon,
    MonetizationOn as MonetizationOnIcon,
} from '@mui/icons-material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PublicIcon from "@mui/icons-material/Public";
import { useOrganizationRoles } from "./shared/useOrganizationRoles";
import { useKeycloak } from '../contexts/KeycloakContext';
import { useUserInfo } from '../hooks/useUserInfo';
import { authService } from '../services/auth.service'; // Import authService

// Constants remain the same
const DRAWER_WIDTH = 250;
const ROLES = {
    MERCHANT: 'merchants',
    ADMIN: 'eventlah-admin',
    AGENT: 'eventlah-agent',
    PAYPROVIDER: 'payment-provider'
};

// Styled Components
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        color: 'var(--gray-400, #888)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '18px',
    },
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: 'var(--gray-400, #888)',
        fontSize: '20px',
    },
    minWidth: '40px',
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[500],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            padding: '10px 16px',
            '& .MuiListItemIcon-root': {
                minWidth: 24,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
                    ),
                },
            },
        },
    },
}));

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { userInfo, roles, isMerchant, isAdmin, isAgent, isPaymentProvider, isLoading: userInfoLoading } = useUserInfo();
    const dispatch = useDispatch();
    const { isAuthenticated, isLoading } = useKeycloak();

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            const fetchOrganizationDetails = async () => {
                if (!userInfo) {
                    return;
                }

                try {
                    const organizationId = userInfo.organization_id;
                    if (!organizationId) {
                        return;
                    }

                    await dispatch(fetchOrganizationProfiles(organizationId)).unwrap();
                } catch (error) {
                    // console.error('Error fetching organization profiles:', error);
                }
            };

            fetchOrganizationDetails();
        }
    }, [dispatch, userInfo, isAuthenticated, isLoading]);

    const storeName = useSelector(state =>
        state.organizationProfiles?.profiles[0]?.data?.name || userInfo?.organization?.[0] || 'Merchant Store Name'
    );

    // Build available roles array
    const availableRoles = [
        ...(isMerchant ? [ROLES.MERCHANT] : []),
        ...(isAdmin ? [ROLES.ADMIN] : []),
        ...(isAgent ? [ROLES.AGENT] : []),
        ...(isPaymentProvider ? [ROLES.PAYPROVIDER] : [])
    ];

    // Set default role based on user's roles
    const [currentRole, setCurrentRole] = useState(() => {
        if (isAgent && !isMerchant) {
            return ROLES.AGENT;
        }
        if (isMerchant) {
            return ROLES.MERCHANT;
        }
        if (isAdmin) {
            return ROLES.ADMIN;
        }
        if (isPaymentProvider) {
            return ROLES.PAYPROVIDER;
        }
        return availableRoles[0];
    });

    // Update current role if it becomes invalid
    useEffect(() => {
        if (availableRoles.length > 0 && !availableRoles.includes(currentRole)) {
            if (isAgent && !isMerchant) {
                setCurrentRole(ROLES.AGENT);
            } else if (isMerchant) {
                setCurrentRole(ROLES.MERCHANT);
            } else if (isAdmin) {
                setCurrentRole(ROLES.ADMIN);
            } else if (isPaymentProvider) {
                setCurrentRole(ROLES.PAYPROVIDER);
            } else {
                setCurrentRole(availableRoles[0]);
            }
        }
    }, [availableRoles, currentRole, isAgent, isMerchant, isAdmin, isPaymentProvider]);

    const handleLogout = () => {
        // keycloak.logout();
    };

    const handleRoleChange = (newRole) => {
        setCurrentRole(newRole);
    };

    const renderView = () => {
        if (!userInfo || availableRoles.length === 0) {
            return null;
        }

        const commonProps = {
            storeName,
            currentRole,
            availableRoles: isAgent && isMerchant ? [ROLES.MERCHANT, ROLES.AGENT] : availableRoles,
            onRoleChange: handleRoleChange
        };

        switch (currentRole) {
            case ROLES.MERCHANT:
                return (
                    <MerchantView
                        {...commonProps}
                        organizationRoles={roles}
                    />
                );
            case ROLES.ADMIN:
                return (
                    <AdminView
                        {...commonProps}
                    />
                );
            case ROLES.AGENT:
                return (
                    <AgentView
                        {...commonProps}
                    />
                );
            case ROLES.PAYPROVIDER:
                return (
                    <PaymentView
                        {...commonProps}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Drawer
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                width: isOpen ? DRAWER_WIDTH : 0,
                flexShrink: 0,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box',
                    backgroundColor: 'background.paper',
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                {renderView()}
                <ListItem button onClick={handleLogout}>
                    <CustomListItemIcon>
                        <ExitToAppIcon />
                    </CustomListItemIcon>
                    <CustomListItemText primary="Logout" />
                </ListItem>
            </Box>
        </Drawer>
    );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
    transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

// Helper Components
const LogoPlaceholder = ({ width = 32, height = 32, logoUrl }) => {
    if (logoUrl) {
        return (
            <div className="flex items-center justify-center" style={{ width, height }}>
                <img 
                    src={logoUrl}
                    alt="Organization logo"
                    style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '4px'
                    }}
                />
            </div>
        );
    }
    
    return (
        <div className="flex items-center justify-center" style={{ width, height }}>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full rounded-md"
            >
                <rect width={width} height={height} fill="#f0f0f0" className="rounded-md" />
                <text
                    x="50%"
                    y="50%"
                    fontSize={width * 0.25}
                    fill="#888"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontFamily="system-ui"
                >
                    LOGO
                </text>
            </svg>
        </div>
    );
};

const getRoleDisplay = (role) => {
    switch (role) {
        case ROLES.MERCHANT:
            return 'Merchant';
        case ROLES.ADMIN:
            return 'Admin';
        case ROLES.AGENT:
            return 'Agent';
        case ROLES.PAYPROVIDER:
            return 'Payment Provider';
        default:
            return role;
    }
};

// Navigation Config
const MERCHANT_MENU_ITEMS = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/' },
    // { text: 'Landing Pages', icon: <PublicIcon />, link: 'http://localhost:3002' },
    { text: 'Landing Pages', icon: <PublicIcon />, link: 'https://pages.eventlah.com' },
    // {
    //     text: 'Events',
    //     icon: <EventNoteIcon />,
    //     link: '/events',
    //     requiredRoles: ['merchants']
    // },
    // {
    //     text: 'Subscriptions',
    //     icon: <AssignmentIcon />,
    //     link: '/subscriptions',
    //     requiredRoles: ['merchants']
    // },
    // {
    //     text: 'Transactions',
    //     icon: <ReceiptIcon />,
    //     link: '/transactions',
    //     requiredRoles: ['merchants']
    // },
    // {
    //     text: 'Settings',
    //     icon: <SettingsIcon />,
    //     link: '/settings',
    //     requiredRoles: ['merchants']
    // }
    // { text: 'Revenue Overview', icon: <MonetizationOnIcon />, link: '/revenue' },
    { text: 'Transactions', icon: <AccountBalanceWalletIcon />, link: '/settings/transaction-history' },
    // { text: 'Products', icon: <StorefrontIcon />, link: '/settings/product-catalog', requiredRoles: ['org_admin']  },
    // { text: 'Orders', icon: <AssignmentIcon />, link: '/order-overview' },
    { text: 'Ticketing', icon: <EventNoteIcon />, link: '/ticketing-overview' },
    // { text: 'Bookings', icon: <RoomPreferencesIcon />, link: '/booking-overview' },
    // { text: 'Appointments', icon: <CalendarTodayIcon />, link: '/settings/appointments' },
    { text: 'Agents', icon: <PeopleIcon />, link: '/agents-overview' },
    { text: 'Billings', icon: <ReceiptIcon />, link: '/settings/account-status#billing' },
    { text: 'Customers', icon: <PeopleIcon />, link: '/settings/customer-records' }
];

const AGENT_MENU_ITEMS = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/agent-dashboard' },
    { text: 'Profile', icon: <PersonAddAlt1Icon />, link: '/agent-profile' },
    { text: 'Commissions', icon: <AttachMoneyIcon />, link: '/agent-commissions' },
    { text: 'Payouts', icon: <PaymentsIcon />, link: '/agent-payouts' },
    { text: 'Materials', icon: <AssignmentIcon />, link: '/agent-materials' }
];

const ADMIN_SUB_MENU_ITEMS = [
    { text: 'Dashboard', link: '/admin/dashboard' },
    { text: 'Merchants', link: '/admin/merchants' },
    { text: 'Agents', link: '/admin/agents' },
    { text: 'Transactions', link: '/admin/transactions' },
    { text: 'Settings', link: '/admin/settings' },
];

// Component Definition
const AccountHeader = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [userAgents, setUserAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [hasLoadedAgents, setHasLoadedAgents] = useState(false);
    const { isAuthenticated, isLoading } = useKeycloak();
    const { userInfo, isLoading: userInfoLoading } = useUserInfo();
    const open = Boolean(anchorEl);
    const { isAgent, isMerchant } = useUserInfo();
    const showRoleSelector = isAgent && isMerchant;
    const organizationLogo = useSelector(state => state.organizationProfiles?.profiles[0]?.data?.logo);

    useEffect(() => {
        const fetchUserAgents = async () => {
            // Only fetch if we haven't loaded agents yet and we have user email
            if (hasLoadedAgents || !userInfo?.email) {
                return;
            }

            try {
                const token = await authService.ensureValidToken();
                
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/user-agents?email=${userInfo.email}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                
                if (response.data.data) {
                    setUserAgents(response.data.data);
                    // Set first agent as default if none selected
                    if (!selectedAgent && response.data.data.length > 0) {
                        setSelectedAgent(response.data.data[0]);
                    }
                }
                // Mark as loaded so we don't fetch again
                setHasLoadedAgents(true);
            } catch (error) {
                console.error('Error fetching user agents:', error);
            }
        };

        fetchUserAgents();
    }, [userInfo?.email, hasLoadedAgents]);

    // Reset hasLoadedAgents when user changes
    useEffect(() => {
        if (!userInfo) {
            setHasLoadedAgents(false);
            setUserAgents([]);
            setSelectedAgent(null);
        }
    }, [userInfo]);

    const handleClick = (event) => {
        if (!showRoleSelector && (!currentRole === ROLES.AGENT || !userAgents.length)) return;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRoleSelect = (role) => {
        if (!showRoleSelector) return;
        onRoleChange(role);
        handleClose();
    };

    const handleAgentSelect = async (agent) => {
        setSelectedAgent(agent);
        handleClose();

        console.log('Start updating Keycloak attributes');

        try {
            // Check authentication first
            if (!authService.isAuthenticated()) {
                console.error('User not authenticated');
                return;
            }

            // Get fresh token for admin token request
            const token = await authService.ensureValidToken();
            if (!token) {
                console.error('Failed to get valid token');
                return;
            }

            // Get user info to get the subject (user ID)
            const userInfo = await authService.getUserInfo();
            if (!userInfo?.sub) {
                console.error('Failed to get user ID');
                return;
            }

            // Get admin token from backend
            const adminTokenResponse = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/admin-token`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const adminToken = adminTokenResponse.data.token;

            // Fetch current user data from Keycloak
            const currentUserResponse = await axios.get(
                `https://accounts.eventlah.com/admin/realms/eventlah/users/${userInfo.sub}`,
                {
                    headers: {
                        'Authorization': `Bearer ${adminToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            const currentUser = currentUserResponse.data;
            const currentAttributes = currentUser.attributes || {};

            // Update the organization_id attribute
            const updatedAttributes = {
                ...currentAttributes,
                organization_id: [agent.organization.id.toString()] // Ensure it's a string and in an array
            };

            // Prepare the update payload
            const updatePayload = {
                ...currentUser,
                attributes: updatedAttributes
            };

            // Remove any read-only fields
            delete updatePayload.createdTimestamp;
            delete updatePayload.totp;
            delete updatePayload.disableableCredentialTypes;
            delete updatePayload.requiredActions;
            delete updatePayload.notBefore;
            delete updatePayload.access;

            // Update the user in Keycloak
            await axios.put(
                `https://accounts.eventlah.com/admin/realms/eventlah/users/${userInfo.sub}`,
                updatePayload,
                {
                    headers: {
                        'Authorization': `Bearer ${adminToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Updated Keycloak attributes');

            // Refresh token
            await authService.refreshToken();
            console.log('Token refreshed');

            // Reload page to reflect the changes
            window.location.reload();

        } catch (error) {
            console.error('Failed to update organization:', error);
            console.error('Error details:', error.response?.data);
            // TODO: Add proper error handling/notification here
        }
    };

    const renderAccountStatus = (status) => {
        let color;
        let icon;
        let label;

        switch (status) {
            case 'verified':
                color = 'success';
                icon = <CheckCircleIcon />;
                label = 'Verified';
                break;
            case 'pending':
                color = 'warning';
                icon = <HelpOutlineIcon />;
                label = 'Pending';
                break;
            case 'suspended':
                color = 'error';
                icon = <HelpOutlineIcon />;
                label = 'Suspended';
                break;
            default:
                color = 'default';
                icon = <HelpOutlineIcon />;
                label = 'Unknown';
        }

        return (
            <Chip
                icon={icon}
                label={label}
                color={color}
                size="small"
                sx={{ fontSize: 10, fontWeight: 'bold' }}
            />
        );
    };

    return (
        <ListItem>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                mt: 1,
            }}>
                <Box sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '4px',
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    mr: 1.5,
                }}>
                    <LogoPlaceholder 
                        width={40} 
                        height={40} 
                        logoUrl={organizationLogo}
                    />
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
                            {currentRole === ROLES.AGENT && selectedAgent 
                                ? selectedAgent.organization.name
                                : storeName}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '12px' }}>
                            {currentRole === ROLES.AGENT && selectedAgent
                                ? `Agent Code: ${selectedAgent.code}`
                                : `Role: ${getRoleDisplay(currentRole)}`}
                        </Typography>

                        {(showRoleSelector || (currentRole === ROLES.AGENT && userAgents.length > 0)) && (
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{
                                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.2s ease-in-out',
                                    padding: '4px',
                                }}
                                aria-controls={open ? 'role-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <KeyboardArrowDownIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                        )}
                    </Box>
                    {renderAccountStatus('verified')}
                </Box>

                <StyledMenu
                    id="role-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ 'aria-labelledby': 'role-button' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    elevation={2}
                >
                    {currentRole === ROLES.AGENT && userAgents.map((agent) => (
                        <StyledMenuItem
                            key={agent.id}
                            onClick={() => handleAgentSelect(agent)}
                            selected={selectedAgent?.id === agent.id}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                padding: 1.5,
                            }}
                        >
                            <Box sx={{
                                width: 32,
                                height: 32,
                                borderRadius: '4px',
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                mr: 1.5,
                            }}>
                                <LogoPlaceholder 
                                    width={32} 
                                    height={32}
                                    logoUrl={agent.organization?.logo}
                                />
                            </Box>

                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>
                                    {agent.organization.name}
                                </Typography>
                                <Typography sx={{ fontWeight: '500', fontSize: '10px', mt: '2px' }}>
                                    Agent Code: {agent.code}
                                </Typography>
                            </Box>

                            {selectedAgent?.id === agent.id && (
                                <CustomListItemIcon sx={{ minWidth: 'auto', marginLeft: 0.5 }}>
                                    <CheckIcon color="primary" fontSize="small" />
                                </CustomListItemIcon>
                            )}
                        </StyledMenuItem>
                    ))}

                    {currentRole === ROLES.AGENT && userAgents.length > 0 && showRoleSelector && (
                        <Divider sx={{ my: 1 }} />
                    )}

                    {showRoleSelector && [ROLES.MERCHANT, ROLES.AGENT].map((role) => (
                        <StyledMenuItem
                            key={role}
                            onClick={() => handleRoleSelect(role)}
                            selected={currentRole === role}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                padding: 1.5,
                            }}
                        >
                            <Box sx={{
                                width: 32,
                                height: 32,
                                borderRadius: '4px',
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                mr: 1.5,
                            }}>
                                <LogoPlaceholder 
                                    width={32} 
                                    height={32}
                                    logoUrl={organizationLogo}
                                />
                            </Box>

                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>{storeName}</Typography>
                                <Typography sx={{ fontWeight: '500', fontSize: '10px', mt: '2px' }}>
                                    Role: {getRoleDisplay(role)}
                                </Typography>
                            </Box>

                            {currentRole === role && (
                                <CustomListItemIcon sx={{ minWidth: 'auto', marginLeft: 0.5 }}>
                                    <CheckIcon color="primary" fontSize="small" />
                                </CustomListItemIcon>
                            )}
                        </StyledMenuItem>
                    ))}
                </StyledMenu>
            </Box>
        </ListItem>
    );
};

const NavigationItem = ({ text, icon, link }) => {
    if (text === 'Landing Pages') {
        return (
            <ListItem
                button
                component="a"
                href={link}
            >
                <CustomListItemIcon>
                    <ExitToAppIcon />
                </CustomListItemIcon>
                <CustomListItemText primary={text} />
            </ListItem>
        );
    }
    return (
        <ListItem
            button
            component={Link}
            to={link}
        >
            <CustomListItemIcon>{icon}</CustomListItemIcon>
            <CustomListItemText primary={text} />
        </ListItem>
    );
};

const MerchantView = ({ storeName, currentRole, availableRoles, onRoleChange, organizationRoles }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    // Filter menu items based on required roles
    const filteredMenuItems = MERCHANT_MENU_ITEMS.filter(item => {
        if (!item.requiredRoles) return true;
        return item.requiredRoles.some(role => organizationRoles.includes(role));
    });

    return (
        <>
            <AccountHeader
                storeName={storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                {filteredMenuItems.map((item, index) => (
                    <NavigationItem
                        key={index}
                        text={item.text}
                        icon={item.icon}
                        link={item.link}
                    />
                ))}
            </List>
        </>
    );
};

const AgentView = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    return (
        <>
            <AccountHeader
                storeName={storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                {AGENT_MENU_ITEMS.map((item, index) => (
                    <NavigationItem
                        key={index}
                        text={item.text}
                        icon={item.icon}
                        link={item.link}
                    />
                ))}
            </List>
        </>
    );
};

const AdminView = ({ currentRole, availableRoles, onRoleChange }) => {
    const [adminMenuOpen, setAdminMenuOpen] = useState(false);

    const handleAdminMenuClick = () => {
        setAdminMenuOpen(!adminMenuOpen);
    };

    return (
        <>
            <AccountHeader
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                {ADMIN_SUB_MENU_ITEMS.map((item, index) => (
                    <ListItem
                        key={index}
                        button
                        component={Link}
                        to={item.link}
                    >
                        <CustomListItemIcon>
                            <AdminIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary={item.text} />
                    </ListItem>
                ))}
                {currentRole === ROLES.ADMIN && (
                    <NavigationItem
                        text="Admin Settings"
                        icon={<AdminIcon />}
                        link="/admin"
                    />
                )}
            </List>
        </>
    );
};

const PaymentView = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    return (
        <>
            <AccountHeader
                storeName={storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                <ListItem button component={Link} to="/payment/dashboard">
                    <CustomListItemIcon>
                        <DashboardIcon />
                    </CustomListItemIcon>
                    <CustomListItemText primary="Dashboard" />
                </ListItem>
            </List>
        </>
    );
};

export default Sidebar;
