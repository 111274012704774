import {useEffect, useState, useCallback, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { createEvent, updateEvent } from '../../../redux/eventsSlice';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    Snackbar,
    Alert,
    CircularProgress, Radio, RadioGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {isAfter, isBefore, isWeekend} from 'date-fns';
import StartEndDate from "./AddEvent/StartEndDate";
import AmenitySettings from "../../settings/product-settings/BookingSetup/add-new-booking/BookingDetails/AmenitySettings";
import React from 'react';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AddTicketingForm = ({ editEvent, onEventCreated }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    // Use useMemo for initial form state
    const initialFormState = useMemo(() => ({
        name: '',
        description: '',
        event_start_date: '',
        event_end_date: '',
        event_type_id: 5,
        status: true,
        minimum_pax: '',
        maximum_pax: '',
        is_daily_events: editEvent?.is_daily_events || false,
    }), [editEvent?.is_daily_events]);

    const [eventData, setEventData] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [weekendsOnly, setWeekendsOnly] = useState(false);
    const [useDisabledDays, setUseDisabledDays] = useState(false);
    const [disabledDays, setDisabledDays] = useState({
        0: false, // Sunday
        1: true,  // Monday
        2: false, // Tuesday
        3: true,  // Wednesday
        4: false, // Thursday
        5: true,  // Friday
        6: false  // Saturday
    });

    // Single effect to handle form initialization
    useEffect(() => {
        let mounted = true;
        
        if (editEvent && mounted) {
            setEventData(prev => ({
                ...prev,
                ...editEvent,
                is_daily_events: editEvent.is_daily_events === true || editEvent.is_daily_events === "true"
            }));
        }
        
        return () => {
            mounted = false;
        };
    }, [editEvent]);

    // Memoized handlers
    const handleChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        setEventData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    }, []);

    const handleDateChange = useCallback((name) => (date) => {
        setEventData(prev => ({
            ...prev,
            [name]: date ? date.toISOString().split('T')[0] : null
        }));
    }, []);

    const handleBlur = useCallback((fieldName) => {
        setTouched(prev => ({
            ...prev,
            [fieldName]: true
        }));
    }, []);

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (loading) return;
        
        setLoading(true);
        try {
            const submissionData = {
                ...eventData,
                amenities: eventData.amenities,
                event_start_date: eventData.event_start_date,
                event_end_date: eventData.event_end_date,
                schedule_start: eventData.schedule_start,
                schedule_end: eventData.schedule_end,
                weekends_only: weekendsOnly,
                use_disabled_days: useDisabledDays,
                disabled_days: disabledDays,
                is_daily_events: eventData.is_daily_events === true || eventData.is_daily_events === "true"
            };

            let resultAction;
            if (editEvent) {
                resultAction = await dispatch(updateEvent(submissionData));
            } else {
                resultAction = await dispatch(createEvent(submissionData));
            }

            if ((editEvent ? updateEvent.fulfilled : createEvent.fulfilled).match(resultAction)) {
                setSnackbarMessage(editEvent ? "Event updated successfully!" : "Event created successfully!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                
                if (onEventCreated) {
                    onEventCreated(resultAction.payload);
                }
                // navigate('/settings/ticketing-setup');
                return;
            }
            
            throw new Error(resultAction.error?.message || `Failed to ${editEvent ? 'update' : 'create'} event`);
        } catch (error) {
            setSnackbarMessage(error.message || `Failed to ${editEvent ? 'update' : 'create'} event`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    }, [loading, editEvent, eventData, weekendsOnly, useDisabledDays, disabledDays, dispatch, navigate, onEventCreated]);

    const handleSnackbarClose = useCallback((event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    }, []);

    const handleWeekendToggle = useCallback((event) => {
        setWeekendsOnly(event.target.checked);
        setStartDate(null);
        setEndDate(null);
    }, []);

    const handleDisabledDaysToggle = useCallback((event) => {
        setUseDisabledDays(event.target.checked);
        setStartDate(null);
        setEndDate(null);
    }, []);

    const handleDayToggle = useCallback((day) => (event) => {
        setDisabledDays(prev => ({ ...prev, [day]: event.target.checked }));
        setStartDate(null);
        setEndDate(null);
    }, []);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setEventData(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    // Memoize shouldDisableDate function
    const shouldDisableDate = useCallback((date) => {
        if (weekendsOnly && !isWeekend(date)) {
            return true;
        }
        if (useDisabledDays) {
            return disabledDays[date.getDay()];
        }
        return false;
    }, [weekendsOnly, useDisabledDays, disabledDays]);

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {/* Event Name Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Event Name</TextFieldTitle>
                    <TextField
                        name="name"
                        value={eventData.name || ''}
                        onChange={handleChange}
                        onBlur={() => handleBlur('name')}
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        fullWidth
                        placeholder='Event name'
                        sx={{paddingRight: { md: '50px', lg: '240px' }}}
                    />
                </Box>

                <Divider />

                {/* Event Type Field */}
                <Box sx={{ display: 'none' }}>
                    <TextFieldTitle>Event Type</TextFieldTitle>
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <RadioGroup
                            name="event_type_id"
                            value="5"
                            onChange={handleChange}
                            row
                        >
                            <FormControlLabel value="5" control={<Radio />} label="Buffet" />
                            <FormControlLabel value="6" control={<Radio />} label="Birthday" />
                            <FormControlLabel value="7" control={<Radio />} label="Wedding" />
                            <FormControlLabel value="9" control={<Radio />} label="Team Building" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                {/* Description Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Description</TextFieldTitle>
                    <TextField
                        name="description"
                        value={eventData.description || ''}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter event description"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                            paddingRight: { md: '50px', lg: '240px' }
                        }}
                    />
                </Box>

                <Divider />

                {/*amenities*/}
                <AmenitySettings
                    amenities={eventData.amenities}
                    onInputChange={handleInputChange}
                />

                <Divider />

                {/* Start Date and End Date Fields */}
                <StartEndDate
                    startDate={eventData.event_start_date ? new Date(eventData.event_start_date) : null}
                    endDate={eventData.event_end_date ? new Date(eventData.event_end_date) : null}
                    onStartDateChange={(date) => handleDateChange('event_start_date')(date)}
                    onEndDateChange={(date) => handleDateChange('event_end_date')(date)}
                    onDailyEventsChange={(isDailyEvents) => {
                        setEventData(prev => ({ 
                            ...prev, 
                            is_daily_events: isDailyEvents
                        }));
                    }}
                    isDailyEvents={eventData.is_daily_events === true || eventData.is_daily_events === "true"}
                />

                <Divider />

                {/* Min-Max Capacity Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Min - Max Capacity(Pax) per day</TextFieldTitle>
                    <Box sx={{ display: 'flex', gap: '20px', width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <TextField
                            type="number"
                            name="minimum_pax"
                            value={eventData.minimum_pax || ''}
                            onChange={handleChange}
                            onBlur={() => handleBlur('minimum_pax')}
                            error={touched.minimum_pax && !!errors.minimum_pax}
                            helperText={touched.minimum_pax && errors.minimum_pax}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ flex: 1 }}
                        />
                        <TextField
                            type="number"
                            name="maximum_pax"
                            value={eventData.maximum_pax || ''}
                            onChange={handleChange}
                            onBlur={() => handleBlur('maximum_pax')}
                            error={touched.maximum_pax && !!errors.maximum_pax}
                            helperText={touched.maximum_pax && errors.maximum_pax}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ flex: 1 }}
                        />
                        <Box sx={{ flex: 2 }}/>
                    </Box>
                </Box>

                <Divider />

                {/* Schedule of live sales */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Schedule of live sales *</TextFieldTitle>
                    <Box sx={{ display: 'flex', gap: '20px', width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                value={eventData.schedule_start ? new Date(eventData.schedule_start) : null}
                                onChange={handleDateChange('schedule_start')}
                                onClose={() => handleBlur('schedule_start')}
                                format="dd/MM/yyyy"
                                shouldDisableDate={shouldDisableDate}
                                slotProps={{
                                    textField: {
                                        error: touched.schedule_start && !!errors.schedule_start,
                                        helperText: touched.schedule_start && errors.schedule_start,
                                        fullWidth: true,
                                    },
                                    day: {
                                        sx: {
                                            "&.Mui-selected": {
                                                backgroundColor: "primary.main",
                                            }
                                        }
                                    }
                                }}
                                sx={{ flex: 1 }}
                            />
                            <DatePicker
                                label="End Date"
                                value={eventData.schedule_end ? new Date(eventData.schedule_end) : null}
                                onChange={handleDateChange('schedule_end')}
                                onClose={() => handleBlur('schedule_end')}
                                format="dd/MM/yyyy"
                                minDate={eventData.schedule_start ? new Date(eventData.schedule_start) : new Date()}
                                disabled={!eventData.schedule_start}
                                shouldDisableDate={shouldDisableDate}
                                slotProps={{
                                    textField: {
                                        error: touched.schedule_end && !!errors.schedule_end,
                                        helperText: touched.schedule_end && errors.schedule_end,
                                        fullWidth: true,
                                    },
                                    day: {
                                        sx: {
                                            "&.Mui-selected": {
                                                backgroundColor: "primary.main",
                                            }
                                        }
                                    }
                                }}
                                sx={{ flex: 1 }}
                            />
                            <Box sx={{ flex: 2 }}/>
                        </LocalizationProvider>
                    </Box>
                </Box>

                <Divider/>

                <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px' }}>
                    <Button variant='cancel' type="button">Cancel</Button>
                    <Button variant='save' type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : (editEvent ? 'Update' : 'Save')}
                    </Button>
                </Box>
            </form>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default React.memo(AddTicketingForm);
