import React, { createContext, useContext, useState, useEffect } from 'react';
import { agentOnboardingService } from '../services/agentOnboardingService';
import { authService } from '../services/auth.service';

const STORAGE_KEY = 'agentOnboardingData';

const AgentOnboardingContext = createContext();

const validatePersonalInfo = (data) => {
    // Required fields for personal info
    const required = ['firstName', 'lastName', 'email', 'addressLine1', 'postcode', 'state', 'country'];
    const hasAllRequired = required.every(field => {
        const value = data.personalInfo[field];
        console.log(`Validating ${field}:`, { value, isValid: value && value.trim() !== '' });
        return value && value.trim() !== '';
    });
    
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmail = emailRegex.test(data.personalInfo.email);
    
    console.log('Personal Info Validation:', {
        hasAllRequired,
        validEmail,
        personalInfo: data.personalInfo
    });
    
    return hasAllRequired && validEmail;
};

const validateBankInfo = (data) => {
    // Required fields for bank info
    const required = ['bankName', 'accountNumber', 'accountName'];
    const hasAllRequired = required.every(field => {
        const value = data.bankInfo[field];
        return value && value.trim() !== '';
    });
    
    console.log('Bank Info Validation:', {
        hasAllRequired,
        data: data.bankInfo
    });
    
    return hasAllRequired;
};

const validateDocuments = (data) => {
    const hasDocuments = Array.isArray(data.documents.otherDocs) && data.documents.otherDocs.length > 0;
    
    console.log('Documents Validation:', {
        hasDocuments,
        count: data.documents.otherDocs?.length
    });
    
    return hasDocuments;
};

export const AgentOnboardingProvider = ({ children }) => {
    const [agentData, setAgentData] = useState(() => {
        const savedData = localStorage.getItem(STORAGE_KEY);
        return savedData ? JSON.parse(savedData) : {
            personalInfo: {
                firstName: '',
                lastName: '',
                email: '',
                addressLine1: '',
                addressLine2: '',
                postcode: '',
                state: '',
                country: 'malaysia',
            },
            bankInfo: {
                bankName: '',
                accountNumber: '',
                accountName: '',
            },
            documents: {
                otherDocs: [],
            },
            stepsCompleted: {
                step1: false,
                step2: false,
                step3: false,
            },
            onboardingRecord: null
        };
    });

    // Fetch onboarding status when component mounts
    useEffect(() => {
        const fetchOnboardingStatus = async () => {
            try {
                const userInfo = await authService.getUserInfo();
                if (userInfo?.email) {
                    const onboardingRecord = await agentOnboardingService.getOnboardingStatus(userInfo.email);
                    setAgentData(prev => ({
                        ...prev,
                        onboardingRecord
                    }));
                }
            } catch (error) {
                console.error('Error fetching onboarding status:', error);
            }
        };

        fetchOnboardingStatus();
    }, []);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(agentData));
    }, [agentData]);

    const updateStepData = async (step, data) => {
        console.log(`Updating ${step} with data:`, data);
        
        try {
            setAgentData(prev => {
                const newState = JSON.parse(JSON.stringify(prev));
                
                if (data.personalInfo) {
                    newState.personalInfo = {
                        ...newState.personalInfo,
                        ...data.personalInfo
                    };
                }
                if (data.bankInfo) {
                    newState.bankInfo = {
                        ...newState.bankInfo,
                        ...data.bankInfo
                    };
                }
                if (data.documents) {
                    newState.documents = {
                        ...newState.documents,
                        ...data.documents
                    };
                }
                
                let isValid = false;
                switch(step) {
                    case 'step1':
                        isValid = validatePersonalInfo(newState);
                        break;
                    case 'step2':
                        isValid = validateBankInfo(newState);
                        break;
                    case 'step3':
                        isValid = validateDocuments(newState);
                        break;
                    default:
                        isValid = false;
                }
                
                newState.stepsCompleted[step] = isValid;
                
                return newState;
            });

            // Update onboarding record in API
            if (agentData.onboardingRecord) {
                const completedSteps = Object.entries(agentData.stepsCompleted)
                    .filter(([_, isComplete]) => isComplete)
                    .map(([step]) => step);

                const currentStep = step;
                const updatedRecord = await agentOnboardingService.updateOnboardingStep(
                    agentData.onboardingRecord.user_id,
                    currentStep,
                    completedSteps
                );

                setAgentData(prev => ({
                    ...prev,
                    onboardingRecord: updatedRecord
                }));
            }
        } catch (error) {
            console.error('Error updating step data:', error);
            throw error;
        }
    };

    const isStepComplete = (step) => {
        // Only check validation, don't require the step to be marked complete
        switch(step) {
            case 'step1':
                return validatePersonalInfo(agentData);
            case 'step2':
                return validateBankInfo(agentData);
            case 'step3':
                return validateDocuments(agentData);
            case 'step4':
                return true; // Review step is always enabled
            default:
                return false;
        }
    };

    return (
        <AgentOnboardingContext.Provider 
            value={{ 
                agentData, 
                updateStepData,
                isStepComplete
            }}
        >
            {children}
        </AgentOnboardingContext.Provider>
    );
};

export const useAgentOnboarding = () => {
    const context = useContext(AgentOnboardingContext);
    if (!context) {
        throw new Error('useAgentOnboarding must be used within an AgentOnboardingProvider');
    }
    return context;
};
