import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Alert,
    CircularProgress,
    Paper,
    Chip
} from '@mui/material';
import {
    CreditCard as CreditCardIcon,
    Add as AddIcon,
} from "@mui/icons-material";
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { authService } from '../../services/auth.service';
import axios from '../../utils/axios';

// Card input styles
const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

// Payment form component
const PaymentForm = ({ onSuccess, onCancel }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setProcessing(true);

        if (!stripe || !elements) {
            setProcessing(false);
            return;
        }

        try {
            const token = await authService.ensureValidToken();
            // Get setup intent
            const { data: { clientSecret } } = await axios.post('/stripe/setup-intent', {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Confirm card setup
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: (await authService.getUserInfo()).email
                    }
                }
            });

            if (result.error) {
                setError(result.error.message);
                setProcessing(false);
            } else {
                onSuccess(result.setupIntent.payment_method);
            }
        } catch (error) {
            setError(error.message);
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
                <CardElement options={cardStyle} />
            </Box>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button onClick={onCancel} disabled={processing}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={!stripe || processing}
                    startIcon={processing ? <CircularProgress size={20} /> : null}
                >
                    Add Card
                </Button>
            </Box>
        </form>
    );
};

// Add Card Dialog Component
const AddCardDialog = ({ open, onClose, onSuccess }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Payment Method</DialogTitle>
            <DialogContent>
                <PaymentForm
                    onSuccess={(paymentMethod) => {
                        onSuccess(paymentMethod);
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogContent>
        </Dialog>
    );
};

const getCardIcon = (brand) => {
    switch (brand?.toLowerCase()) {
        case 'visa':
            return '💳 Visa';
        case 'mastercard':
            return '💳 Mastercard';
        case 'amex':
            return '💳 Amex';
        default:
            return '💳';
    }
};

const OnboardingPaymentMethod = ({ onPaymentMethodAdded, existingPaymentMethod }) => {
    const [openAddCard, setOpenAddCard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(existingPaymentMethod);

    const handleAddCard = async (paymentMethodId) => {
        try {
            setLoading(true);
            const token = await authService.ensureValidToken();
            
            // Set as default payment method
            await axios.post('/stripe/payment-methods', {
                paymentMethodId,
                setAsDefault: true
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setPaymentMethod(paymentMethodId);
            onPaymentMethodAdded && onPaymentMethodAdded(paymentMethodId);
        } catch (error) {
            setError(error.message);
            console.error('Error adding payment method:', error);
        } finally {
            setLoading(false);
        }
    };

    // If we have an existing payment method and haven't proceeded yet, auto-proceed
    useEffect(() => {
        if (existingPaymentMethod && !paymentMethod) {
            onPaymentMethodAdded && onPaymentMethodAdded(existingPaymentMethod.id);
        }
    }, [existingPaymentMethod, paymentMethod, onPaymentMethodAdded]);

    return (
        <Box>
            {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                </Alert>
            )}

            {!paymentMethod && !existingPaymentMethod ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                    <CreditCardIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                    <Typography variant="h6" gutterBottom>
                        No Payment Method Added
                    </Typography>
                    <Typography color="textSecondary" align="center" sx={{ mb: 3 }}>
                        Please add a payment method to continue with the onboarding process
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenAddCard(true)}
                        disabled={loading}
                    >
                        Add Payment Method
                    </Button>
                </Box>
            ) : (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        mb: 2,
                        p: 2,
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1,
                        bgcolor: 'primary.lighter'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 2 }}>
                            <Typography variant="h6">
                                {getCardIcon(existingPaymentMethod?.card?.brand)}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                                {existingPaymentMethod?.card?.brand?.charAt(0).toUpperCase() + existingPaymentMethod?.card?.brand?.slice(1)} 
                                •••• {existingPaymentMethod?.card?.last4}
                                <Chip 
                                    label="Default" 
                                    size="small" 
                                    color="primary" 
                                    sx={{ ml: 1 }}
                                />
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Expires {existingPaymentMethod?.card?.exp_month}/{existingPaymentMethod?.card?.exp_year}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <AddCardDialog
                open={openAddCard}
                onClose={() => setOpenAddCard(false)}
                onSuccess={handleAddCard}
            />
        </Box>
    );
};

export default OnboardingPaymentMethod;
