import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    List, 
    ListItem, 
    LinearProgress, 
    Link,
    IconButton,
    Tooltip,
    Popover,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { agentOnboardingService } from '../../../services/agentOnboardingService';
import { authService } from '../../../services/auth.service';
import { useNavigate, useSearchParams } from 'react-router-dom';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(1),
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        transform: 'translateX(8px)',
        '& .MuiSvgIcon-root.arrow': {
            opacity: 1,
            transform: 'translateX(0)',
        },
    },
    cursor: 'pointer',
    position: 'relative',
}));

const StyledLink = styled(Box)(({ theme }) => ({
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
        textDecoration: 'none',
    },
}));

const AgentChecklist = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeDescription, setActiveDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [onboardingData, setOnboardingData] = useState(null);

    useEffect(() => {
        const fetchOnboardingStatus = async () => {
            try {
                const userInfo = await authService.getUserInfo();
                if (!userInfo?.email) {
                    setError('User information not available');
                    return;
                }

                const onboardingRecord = await agentOnboardingService.getOnboardingStatus(userInfo.email);
                setOnboardingData(onboardingRecord);
                setError(null);
            } catch (err) {
                console.error('Error fetching onboarding status:', err);
                setError('Failed to fetch onboarding status');
            } finally {
                setLoading(false);
            }
        };

        fetchOnboardingStatus();
    }, []);

    const handleInfoClick = (event, description) => {
        event.preventDefault();
        event.stopPropagation();
        setActiveDescription(description);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStepClick = (stepId, index, isLocked) => {
        if (!isLocked) {
            const params = new URLSearchParams(searchParams.toString());
            params.set('step', stepId);
            setSearchParams(params);
            navigate(`/agent-onboarding?${params.toString()}`);
        }
    };

    const open = Boolean(anchorEl);

    const checklistItems = [
        {
            id: 'step1',
            label: 'Personal Information',
            description: 'Complete your personal information and contact details',
        },
        {
            id: 'step2',
            label: 'Bank Details',
            description: 'Add your bank account information for commission payments',
        },
        {
            id: 'step3',
            label: 'Document Verification',
            description: 'Upload required documents for verification',
        }
    ].map(item => ({
        ...item,
        completed: onboardingData?.completed_steps?.includes(item.id) || false
    }));

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={2}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    const completedSteps = checklistItems.filter(item => item.completed).length;
    const progress = (completedSteps / checklistItems.length) * 100;

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" component="h2">
                    Agent Onboarding Progress
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {completedSteps} of {checklistItems.length} completed
                </Typography>
            </Box>

            <LinearProgress 
                variant="determinate" 
                value={progress} 
                sx={{ mb: 2, height: 8, borderRadius: 4 }}
            />

            <List>
                {checklistItems.map((item, index) => {
                    const isLocked = index > 0 && !checklistItems[index - 1].completed;
                    
                    return (
                        <StyledListItem 
                            key={item.id} 
                            disableGutters
                            onClick={() => handleStepClick(item.id, index, isLocked)}
                            sx={{
                                opacity: isLocked ? 0.5 : 1,
                                cursor: isLocked ? 'not-allowed' : 'pointer',
                            }}
                        >
                            <StyledLink>
                                <Box display="flex" alignItems="center" width="100%">
                                    {item.completed ? (
                                        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                    ) : (
                                        <CancelIcon color="disabled" sx={{ mr: 1 }} />
                                    )}
                                    <Box flex={1}>
                                        <Typography variant="body1">{item.label}</Typography>
                                    </Box>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleInfoClick(e, item.description)}
                                        sx={{ mr: 1 }}
                                    >
                                        <InfoOutlinedIcon fontSize="small" />
                                    </IconButton>
                                    <ArrowForwardIosIcon 
                                        className="arrow" 
                                        sx={{ 
                                            opacity: 0,
                                            transform: 'translateX(-10px)',
                                            transition: 'all 0.2s ease-in-out',
                                            fontSize: 16
                                        }} 
                                    />
                                </Box>
                            </StyledLink>
                        </StyledListItem>
                    );
                })}
            </List>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ p: 2, maxWidth: 300 }}>
                    <Typography variant="body2">{activeDescription}</Typography>
                </Box>
            </Popover>
        </Box>
    );
};

export default AgentChecklist;