import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Link,
    IconButton,
    FormControl,
    Divider,
    Chip,
    Badge,
    Tooltip,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
} from '@mui/material';
import {
    Info,
    ContentCopy,
    CheckCircle,
    Warning,
    AccessTime,
    MoreVert,
    Shield,
    Security,
    Google,
    PersonAdd as NewMemberIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import AddMemberModal from './AddMemberModal';
import PermissionGate from 'components/shared/PermissionGate';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const StatusChip = ({ type, label }) => {
    const getChipProps = () => {
        switch (type) {
            case 'invited':
                return {
                    icon: <AccessTime sx={{ fontSize: 16 }} />,
                    color: 'warning',
                    label,
                    size: 'medium',
                };
            case 'active':
                return {
                    icon: <CheckCircle sx={{ fontSize: 16 }} />,
                    color: 'success',
                    label,
                    size: 'medium',
                };
            case 'limited':
                return {
                    icon: <Warning sx={{ fontSize: 16 }} />,
                    color: 'default',
                    label,
                    size: 'medium',
                };
            default:
                return { label, size: 'medium' };
        }
    };

    return <Chip {...getChipProps()} sx={{ height: 24 }} />;
};

const ViewRolePermissions = () => {
    const [openModal, setOpenModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    const { userInfo } = useKeycloak();

    const fetchMembers = async () => {
        try {
            // Mock data for members
            const mockMembers = [
                {
                    id: '1',
                    user: {
                        first_name: 'John',
                        last_name: 'Doe',
                        email: 'john.doe@example.com'
                    },
                    is_default: true,
                    updated_at: new Date().toISOString()
                },
                {
                    id: '2',
                    user: {
                        first_name: 'Jane',
                        last_name: 'Smith',
                        email: 'jane.smith@example.com'
                    },
                    is_default: false,
                    updated_at: new Date().toISOString()
                }
            ];

            // Mock data for roles
            const mockRoles = [
                { id: '1', name: 'Admin' },
                { id: '2', name: 'Editor' },
                { id: '3', name: 'Viewer' }
            ];

            setMembers(mockMembers);
            setRoles(mockRoles);
            setLoading(false);
        } catch (error) {
            console.error('Error setting mock data:', error);
            setSnackbar({
                open: true,
                message: 'Failed to load team members',
                severity: 'error',
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMembers();
    }, []);

    const handleAddMember = async (email, selectedRoles) => {
        try {
            setLoading(true);
            
            // Create mock member data
            const newMember = {
                id: String(Math.random()).slice(2, 8),
                user: {
                    first_name: email.split('@')[0],
                    last_name: '',
                    email: email
                },
                is_default: false,
                updated_at: new Date().toISOString()
            };

            setMembers(prevMembers => [...prevMembers, newMember]);
            setSnackbar({
                open: true,
                message: 'Member invited successfully',
                severity: 'success'
            });
            setOpenModal(false);
        } catch (error) {
            console.error('Error inviting member:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.description || 'Error inviting member',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const formatLastActive = date => {
        if (!date) return 'Never';
        return new Date(date).toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
        });
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: '850px' }}>
            {/* Header Section */}
            <Box sx={{ mb: 4, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant='h5' sx={{ fontWeight: '600', mb: 1 }}>
                        Users and permissions
                    </Typography>
                    <Typography variant='body1' color='text.secondary'>
                        Manage access and control for your team members
                    </Typography>
                </Box>
            </Box>

            {/* Store Owner Section */}
            <Paper
                variant='outlined'
                sx={{
                    mb: 3,
                    borderRadius: 2,
                    borderColor: 'primary.light',
                    bgcolor: 'primary.50',
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Security sx={{ color: 'primary.main', mr: 1 }} />
                        <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
                            Store owner
                        </Typography>
                    </Box>
                    <List sx={{ p: 0 }}>
                        <ListItem
                            sx={{
                                px: 0,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                                '&:hover': { bgcolor: 'grey.50' },
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        borderRadius: 2,
                                        bgcolor: 'primary.main',
                                        width: 40,
                                        height: 40,
                                    }}
                                >
                                    {userInfo?.email?.[0]}
                                    {userInfo?.lastName?.[0]}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: '500' }}>
                                            {userInfo?.email} {userInfo?.firstName}
                                        </Typography>
                                        <Tooltip title='Full administrative access'>
                                            <Info fontSize='small' sx={{ ml: 1, color: 'primary.main' }} />
                                        </Tooltip>
                                    </Box>
                                }
                                secondary={
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                                        <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
                                            {userInfo?.email}
                                        </Typography>
                                        <AccessTime sx={{ fontSize: 14, mr: 0.5, color: 'text.secondary' }} />
                                        <Typography variant='caption' color='text.secondary'>
                                            Last active:{' '}
                                            {new Date().toLocaleString('en-US', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                timeZoneName: 'short',
                                            })}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <StatusChip type='active' label='Active' />
                        </ListItem>
                    </List>
                </Box>
            </Paper>

            {/* Staff Section */}
            <Box>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Typography variant='subtitle1' sx={{ fontWeight: '600', mb: 0.5 }}>
                                Staff members
                            </Typography>
                            <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
                                {members.length} members
                            </Typography>
                        </Box>
                        {/*<PermissionGate featureCode='CRTTEM' fallback={null}>*/}
                            <Button
                                variant='contained'
                                startIcon={<NewMemberIcon />}
                                onClick={() => setOpenModal(true)}
                                sx={{ textTransform: 'none' }}
                            >
                                Add Member
                            </Button>
                        {/*</PermissionGate>*/}
                    </Box>
                    <List sx={{ p: 0 }}>
                        {members.map((member, index) => (
                            <React.Fragment key={member.id}>
                                <ListItem
                                    sx={{
                                        px: 0,
                                        py: 2,
                                        borderRadius: 1,
                                        '&:hover': {
                                            bgcolor: 'grey.50',
                                            '& .actions': { visibility: 'visible' },
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ borderRadius: 1.5, bgcolor: `hsl(${index * 40}, 70%, 50%)` }}>
                                            {member.user?.first_name?.[0]}
                                            {member.user?.last_name?.[0]}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography sx={{ fontWeight: '500' }}>
                                                    {member.user?.first_name} {member.user?.last_name}
                                                </Typography>
                                                <Typography variant='body2' color='text.secondary' sx={{ ml: 1 }}>
                                                    ({member.user?.email})
                                                </Typography>
                                            </Box>
                                        }
                                        secondary={
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                                                <AccessTime sx={{ fontSize: 14, mr: 0.5, color: 'text.secondary' }} />
                                                <Typography variant='caption' color='text.secondary'>
                                                    Last active: {formatLastActive(member.updated_at)}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <StatusChip
                                            type={member.is_default ? 'active' : 'limited'}
                                            label={member.is_default ? 'Active' : 'Limited'}
                                        />
                                    </Box>
                                </ListItem>
                                {index < members.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Box>

            <Divider sx={{ mt: 1 }} />

            <AddMemberModal open={openModal} onClose={() => setOpenModal(false)} onSubmit={handleAddMember} />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant='filled'>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ViewRolePermissions;
