import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import PaymentDomain from './PaymentDomain';
import PermissionGate from 'components/shared/PermissionGate';

const Payment = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderTabContent = index => {
        switch (index) {
            case 0:
                return (
                    <PermissionGate featureCode='PYMT_READ' fallback={null}>
                        <PaymentMethod />
                    </PermissionGate>
                );
            case 1:
                return (
                    <PermissionGate featureCode='PYMT_READ1' fallback={null}>
                        <PaymentDomain />
                    </PermissionGate>
                );
            default:
                return null;
        }
    };

    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Payment</Typography>
                        <Typography variant='h5'>Set up and manage payment methods and transactions.</Typography>
                    </Box>
                </Box>

                {/*<Box height='38px' paddingTop='32px'>*/}
                {/*    <Tabs value={value} onChange={handleTabChange} aria-label='service tabs'>*/}
                {/*        <PermissionGate featureCode='PYMT_READ' fallback={null}>*/}
                {/*            <Tab label='Payment Method' />*/}
                {/*        </PermissionGate>*/}
                {/*        <PermissionGate featureCode='PYMT_READ1' fallback={null}>*/}
                {/*            <Tab label='Payment Domain' />*/}
                {/*        </PermissionGate>*/}
                {/*    </Tabs>*/}
                {/*</Box>*/}

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>{renderTabContent(value)}</Box>
            </Box>
        </Box>
    );
};

export default Payment;
