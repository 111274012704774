import React, { useState, useEffect, useCallback } from 'react';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import { authService } from '../../../../services/auth.service';
import {
    Box,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
} from '@mui/material';
import {
    CreditCard as CreditCardIcon
} from "@mui/icons-material";
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import axios from '../../../../utils/axios';

// Card input styles
const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

// Status chip component
const StatusChip = ({ status }) => {
    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'active':
                return { color: 'success', label: 'Active' };
            case 'inactive':
                return { color: 'error', label: 'Inactive' };
            case 'pending':
                return { color: 'warning', label: 'Pending' };
            default:
                return { color: 'default', label: status };
        }
    };

    const { color, label } = getStatusColor(status);

    return (
        <Chip
            label={label}
            color={color}
            size="small"
            sx={{ textTransform: 'capitalize' }}
        />
    );
};

// Payment form component
const PaymentForm = ({ onSuccess, onCancel }) => {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setProcessing(true);

        if (!stripe || !elements) {
            setProcessing(false);
            return;
        }

        try {
            const token = await authService.ensureValidToken();
            
            // Get setup intent
            const { data: { clientSecret } } = await axios.post('/stripe/setup-intent');

            // Confirm card setup
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                }
            });

            if (result.error) {
                setError(result.error.message);
            } else {
                // Save payment method
                await axios.post('/stripe/payment-methods', {
                    paymentMethodId: result.setupIntent.payment_method
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                onSuccess();
            }
        } catch (error) {
            setError(error.response?.data?.error || 'An error occurred while processing your card');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ p: 2 }}>
                <CardElement options={cardStyle} />
                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button 
                    type="submit" 
                    variant="contained" 
                    disabled={!stripe || processing}
                >
                    {processing ? 'Processing...' : 'Save Card'}
                </Button>
            </DialogActions>
        </form>
    );
};

// Add Card Dialog Component
const AddCardDialog = ({ open, onClose, onSuccess }) => {
    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Add New Card</DialogTitle>
            <DialogContent>
                <PaymentForm 
                    onSuccess={() => {
                        onSuccess();
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogContent>
        </Dialog>
    );
};

const Subscriptions = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [openCardDialog, setOpenCardDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [creatingCharge, setCreatingCharge] = useState(false);
    const { isAuthenticated } = useKeycloak();
    const [token, setToken] = useState(null);

    // Token retrieval effect
    useEffect(() => {
        const getToken = async () => {
            try {
                const newToken = await authService.ensureValidToken();
                setToken(newToken);
            } catch (error) {
                console.error('Error getting token:', error);
            }
        };
        getToken();
    }, []);

    // Fetch payment methods effect
    const fetchPaymentMethods = useCallback(async () => {
        if (!isAuthenticated || !token) {
            setLoading(false);
            return;
        }

        try {
            const { data: paymentMethods } = await axios.get('/stripe/payment-methods', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            // Find default payment method (the first one is default)
            const defaultMethod = paymentMethods.length > 0 ? paymentMethods[0] : null;
            
            setPaymentMethods(paymentMethods);
            setDefaultPaymentMethod(defaultMethod);
        } catch (error) {
            console.error('Error fetching payment methods:', error);
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, token]);

    useEffect(() => {
        fetchPaymentMethods();
    }, [fetchPaymentMethods]);

    // Handle card removal
    const handleRemoveCard = async (paymentMethodId) => {
        try {
            const token = await authService.ensureValidToken();
            await axios.delete(`/stripe/payment-methods?paymentMethodId=${paymentMethodId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            // Refresh payment methods
            const { data: updatedPaymentMethods } = await axios.get('/stripe/payment-methods', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setPaymentMethods(updatedPaymentMethods);
            setDefaultPaymentMethod(updatedPaymentMethods.length > 0 ? updatedPaymentMethods[0] : null);
        } catch (error) {
            console.error('Error removing card:', error);
            alert('Failed to remove card. Please try again.');
        }
    };

    // Handle setting default payment method
    const handleSetDefaultCard = async (paymentMethodId) => {
        try {
            const token = await authService.ensureValidToken();
            await axios.post('/stripe/payment-methods', {
                paymentMethodId,
                setAsDefault: true
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            // Refresh payment methods
            const { data: updatedPaymentMethods } = await axios.get('/stripe/payment-methods', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setPaymentMethods(updatedPaymentMethods);
            setDefaultPaymentMethod(updatedPaymentMethods.length > 0 ? updatedPaymentMethods[0] : null);
        } catch (error) {
            console.error('Error setting default payment method:', error);
            alert('Failed to set default card. Please try again.');
        }
    };

    // Handle creating scheduled charge
    const handleCreateScheduledCharge = async (date, amount) => {
        try {
            setCreatingCharge(true);
            const token = await authService.ensureValidToken();

            // Parse the date string and create period start/end
            const chargeDate = new Date(date);
            const periodStart = new Date(chargeDate);
            periodStart.setDate(periodStart.getDate() - 30); // 30 days before charge date
            const periodEnd = new Date(chargeDate);
            periodEnd.setDate(periodEnd.getDate() - 1); // day before charge date

            const response = await axios.post('/platform/scheduled-charges', {
                amount: parseFloat(amount.replace(/[^0-9.]/g, '')), // Remove currency symbol and convert to number
                scheduledDate: chargeDate.toISOString(),
                periodStart: periodStart.toISOString(),
                periodEnd: periodEnd.toISOString(),
                totalTickets: 100 // Example value
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log('Scheduled charge created:', response.data);
            alert('Scheduled charge created successfully!');
        } catch (error) {
            console.error('Error creating scheduled charge:', error);
            alert('Error creating scheduled charge: ' + (error.response?.data?.error || error.message));
        } finally {
            setCreatingCharge(false);
        }
    };

    const getCardIcon = (brand) => {
        switch (brand.toLowerCase()) {
            case 'visa':
                return '💳 Visa';
            case 'mastercard':
                return '💳 Mastercard';
            case 'amex':
                return '💳 Amex';
            default:
                return '💳';
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            {/* Payment Method Section */}
            <Typography 
                variant="h6" 
                sx={{ 
                    mb: 3,
                    color: 'text.primary',
                    fontWeight: 600
                }}
            >
                Payment Method
            </Typography>
            <Paper sx={{ p: 3, mb: 4 }}>
                {loading ? (
                    <Typography>Loading payment methods...</Typography>
                ) : paymentMethods.length > 0 ? (
                    paymentMethods.map((method) => (
                        <Box 
                            key={method.id} 
                            sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'space-between',
                                mb: 2,
                                p: 2,
                                border: '1px solid',
                                borderColor: method.id === defaultPaymentMethod?.id ? 'primary.main' : 'divider',
                                borderRadius: 1,
                                bgcolor: method.id === defaultPaymentMethod?.id ? 'primary.lighter' : 'background.paper'
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ mr: 2 }}>
                                    <Typography variant="h6">{getCardIcon(method.card.brand)}</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                                        {method.card.brand.charAt(0).toUpperCase() + method.card.brand.slice(1)} •••• {method.card.last4}
                                        {method.id === defaultPaymentMethod?.id && (
                                            <Chip 
                                                label="Default" 
                                                size="small" 
                                                color="primary" 
                                                sx={{ ml: 1 }}
                                            />
                                        )}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Expires {method.card.exp_month.toString().padStart(2, '0')}/{method.card.exp_year}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                {method.id !== defaultPaymentMethod?.id && (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{ mr: 1 }}
                                        onClick={() => handleSetDefaultCard(method.id)}
                                    >
                                        Set as Default
                                    </Button>
                                )}
                                <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={() => handleRemoveCard(method.id)}
                                >
                                    Remove
                                </Button>
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body2" color="text.secondary">
                        No payment methods added yet.
                    </Typography>
                )}
                <Button
                    variant="contained"
                    size="small"
                    sx={{ mt: 2 }}
                    onClick={() => setOpenCardDialog(true)}
                >
                    Add New Card
                </Button>
                <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                    This card will be used for all automatic payments including monthly subscription and ticketing fees.
                </Typography>
            </Paper>

            {/* Monthly Subscription Section */}
            <Typography 
                variant="h6" 
                sx={{ 
                    mt: 4,
                    mb: 3,
                    color: 'text.primary',
                    fontWeight: 600
                }}
            >
                Monthly Subscription
            </Typography>
            <Paper sx={{ p: 3, mb: 4 }}>
                <Box sx={{ mb: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">
                            Basic Plan
                        </Typography>
                        <StatusChip status="active" />
                    </Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        RM 99/month
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Next billing date: 2025-02-10
                    </Typography>
                </Box>

                <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Upgrade Plan
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                    >
                        Cancel Subscription
                    </Button>
                </Box>
            </Paper>

            {/* Ticketing Transactions Section */}
            <Typography 
                variant="h6" 
                sx={{ 
                    mt: 4,
                    mb: 3,
                    color: 'text.primary',
                    fontWeight: 600
                }}
            >
                Ticketing Transactions
            </Typography>

            {/* Scheduled Payments Info */}
            <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ 
                    bgcolor: 'primary.lighter', 
                    p: 2, 
                    borderRadius: 1,
                    flex: 1
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                        <Typography variant="subtitle2" sx={{ color: 'primary.darker' }}>
                            Next Scheduled Payment
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleCreateScheduledCharge('2025-01-12', 'RM 3,160.00')}
                            disabled={creatingCharge}
                        >
                            {creatingCharge ? 'Creating...' : 'Create Charge'}
                        </Button>
                    </Box>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Jan 12, 2025 • RM 3,160.00
                    </Typography>
                </Box>
                <Box sx={{ 
                    bgcolor: 'primary.lighter', 
                    p: 2, 
                    borderRadius: 1,
                    flex: 1
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                        <Typography variant="subtitle2" sx={{ color: 'primary.darker' }}>
                            Following Payment
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleCreateScheduledCharge('2025-01-14', 'RM 2,590.00')}
                            disabled={creatingCharge}
                        >
                            {creatingCharge ? 'Creating...' : 'Create Charge'}
                        </Button>
                    </Box>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Jan 14, 2025 • RM 2,590.00
                    </Typography>
                </Box>
            </Box>

            <Paper sx={{ p: 3 }}>
                {/* Current Period Transactions */}
                <Typography variant="subtitle1" sx={{ mb: 2 }}>Current Period (To be charged on Jan 14, 2025)</Typography>
                <TableContainer sx={{ mb: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Period</TableCell>
                                <TableCell>Total Tickets Generated</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Fee Breakdown</TableCell>
                                <TableCell align="right">Processing Fee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Jan 12 - Jan 13, 2025</TableCell>
                                <TableCell>
                                    600
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                                        (exclude ticket no #1-#20)
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Box>
                                        <Typography variant="body2">• First 20 tickets: Free</Typography>
                                        <Typography variant="body2">• 21-250 tickets: 3% (230 tickets)</Typography>
                                        <Typography variant="body2">• 251-500 tickets: 2.6% (250 tickets)</Typography>
                                        <Typography variant="body2">• 500+ tickets: 2.4% (100 tickets)</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="right">RM 3,160.00</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Next Period Transactions */}
                <Typography variant="subtitle1" sx={{ mb: 2 }}>Next Period (To be charged on Jan 16, 2025)</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Period</TableCell>
                                <TableCell>Total Tickets Generated</TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Fee Breakdown</TableCell>
                                <TableCell align="right">Processing Fee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Jan 14 - Jan 15, 2025</TableCell>
                                <TableCell>
                                    450
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                                        (exclude ticket no #1-#20)
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Box>
                                        <Typography variant="body2">• First 20 tickets: Free</Typography>
                                        <Typography variant="body2">• 21-250 tickets: 3% (230 tickets)</Typography>
                                        <Typography variant="body2">• 251-450 tickets: 2.6% (200 tickets)</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="right">RM 2,590.00</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ mt: 3, pt: 2, borderTop: '1px solid rgba(224, 224, 224, 1)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                        Transactions are processed every 2 days
                    </Typography>
                    <Typography variant="h6">
                        Total Processing Fees: RM 5,750.00
                    </Typography>
                </Box>
            </Paper>

            {/* Add Card Dialog */}
            <AddCardDialog 
                open={openCardDialog}
                onClose={() => setOpenCardDialog(false)}
                onSuccess={fetchPaymentMethods}
            />
        </Box>
    );
};

export default Subscriptions;
