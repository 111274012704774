const LOG_LEVELS = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR'
};

class Logger {
    constructor() {
        // Load existing logs from localStorage
        try {
            this.logs = JSON.parse(localStorage.getItem('eventlah_logs') || '[]');
        } catch (e) {
            this.logs = [];
        }
        this.maxLogs = 1000; // Keep last 1000 logs in memory and storage
        
        // Set up auto-save interval (every 5 minutes)
        this.autoSaveInterval = 5 * 60 * 1000; // 5 minutes
        this.setupAutoSave();
        this.hasNewLogs = false;
        this.fileHandle = null;
    }

    setupAutoSave() {
        // Save to localStorage every minute
        setInterval(() => {
            this.saveToLocalStorage();
        }, 60 * 1000);

        // Save to file every 5 minutes if there are new logs
        setInterval(() => {
            if (this.hasNewLogs) {
                this.saveToFile();
                this.hasNewLogs = false;
            }
        }, this.autoSaveInterval);

        // Save before user leaves the page
        window.addEventListener('beforeunload', () => {
            this.saveToLocalStorage();
        });
    }

    saveToLocalStorage() {
        try {
            localStorage.setItem('eventlah_logs', JSON.stringify(this.logs));
        } catch (e) {
            console.error('Failed to save logs to localStorage:', e);
        }
    }

    formatMessage(level, message, data = {}) {
        const timestamp = new Date().toISOString();
        const logEntry = {
            timestamp,
            level,
            message,
            data
        };

        // Keep logs in memory and localStorage
        this.logs.push(logEntry);
        if (this.logs.length > this.maxLogs) {
            this.logs.shift();
        }
        this.hasNewLogs = true;
        this.saveToLocalStorage();

        // Format for file output
        const formattedMessage = `${timestamp} [${level}] ${message} ${JSON.stringify(data)}`;

        // In development, also log to console with different colors
        if (process.env.NODE_ENV === 'development') {
            const consoleColors = {
                [LOG_LEVELS.DEBUG]: '\x1b[36m', // Cyan
                [LOG_LEVELS.INFO]: '\x1b[32m',  // Green
                [LOG_LEVELS.WARN]: '\x1b[33m',  // Yellow
                [LOG_LEVELS.ERROR]: '\x1b[31m'  // Red
            };
            console.log(`${consoleColors[level]}${formattedMessage}\x1b[0m`);
        }

        return formattedMessage;
    }

    debug(message, data) {
        return this.formatMessage(LOG_LEVELS.DEBUG, message, data);
    }

    info(message, data) {
        return this.formatMessage(LOG_LEVELS.INFO, message, data);
    }

    warn(message, data) {
        return this.formatMessage(LOG_LEVELS.WARN, message, data);
    }

    error(message, data) {
        return this.formatMessage(LOG_LEVELS.ERROR, message, data);
    }

    getLogs() {
        return this.logs;
    }

    clearLogs() {
        this.logs = [];
        localStorage.removeItem('eventlah_logs');
        console.log('Logs cleared');
    }

    // Save logs to file using the File System Access API
    async saveToFile() {
        try {
            const blob = new Blob([this.logs.map(log => 
                `${log.timestamp} [${log.level}] ${log.message} ${JSON.stringify(log.data)}\n`
            ).join('')], { type: 'text/plain' });

            // Try to get the previous file handle
            let handle = this.fileHandle;
            
            // If no file handle exists, ask user where to save
            if (!handle) {
                handle = await window.showSaveFilePicker({
                    suggestedName: `eventlah-logs-${new Date().toISOString().split('T')[0]}.log`,
                    types: [{
                        description: 'Log Files',
                        accept: { 'text/plain': ['.log'] },
                    }],
                });
                this.fileHandle = handle; // Save the handle for future use
            }

            const writable = await handle.createWritable();
            await writable.write(blob);
            await writable.close();
        } catch (error) {
            console.error('Failed to save logs to file:', error);
            // Clear the file handle if there was an error
            this.fileHandle = null;
        }
    }

    // Manual save with new file
    async saveLogs() {
        // Clear the existing file handle to force a new save dialog
        this.fileHandle = null;
        return this.saveToFile();
    }
}

export const logger = new Logger();
logger.clearLogs();
