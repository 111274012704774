import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Box,
    Button,
    Divider,
    Paper,
    Typography,
    Stepper,
    Step,
    StepLabel,
    TextField,
    Alert,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '../../contexts/KeycloakContext';
import { setCurrentStep, updateOnboardingRecord } from '../../redux/accountOnboardingSlice';
import { useDispatch } from 'react-redux';
import { authService } from '../../services/auth.service';
import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

class SixthStepErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error('SixthStep Error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box sx={{ p: 3 }}>
                    <Alert severity="error">
                        Something went wrong. Please try refreshing the page.
                        {this.state.error && (
                            <Typography variant="caption" display="block">
                                Error: {this.state.error.message}
                            </Typography>
                        )}
                    </Alert>
                </Box>
            );
        }

        return this.props.children;
    }
}

const SixthStep = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated, userInfo } = useKeycloak();
    const [tosText, setTosText] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const organizationId = useMemo(() => userInfo?.organization_id, [userInfo]);

    const fetchTosData = useCallback(async () => {
        if (!organizationId || !isAuthenticated || !authService.getAccessToken()) {
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${BASE_API_URL}/organizations?id=${organizationId}`, {
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`
                }
            });

            if (response.data.code === 200 && response.data.data?.terms_of_service) {
                setTosText(response.data.data.terms_of_service);
            }
        } catch (err) {
            console.error('Error fetching TOS:', err);
            setError('Failed to load Terms of Service. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [organizationId, isAuthenticated]);

    useEffect(() => {
        fetchTosData();
    }, [fetchTosData]);

    const handleConfirm = async () => {
        if (!tosText.trim()) {
            setError('Please enter your Terms of Service');
            return;
        }

        if (!isAuthenticated || !authService.getAccessToken()) {
            setError('Authentication required. Please log in.');
            return;
        }

        try {
            setLoading(true);

            // First, update the TOS in the organization
            await axios.patch(
                `${BASE_API_URL}/organizations?id=${organizationId}`,
                {
                    terms_of_service: tosText
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authService.getAccessToken()}`
                    }
                }
            );

            // Then update the onboarding record
            const result = await dispatch(
                updateOnboardingRecord({
                    email: userInfo?.email,
                    onboardingData: {
                        current_step: 'completed',
                        completed_steps: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh'],
                        tos_data: {
                            terms_of_service: tosText,
                        },
                    },
                })
            ).unwrap();

            dispatch(setCurrentStep('finish'));
            navigate('/finish');
        } catch (error) {
            console.error('Failed to update:', error);
            setError('Failed to save changes. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        dispatch(setCurrentStep('fifth'));
        navigate('/fifth');
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!isAuthenticated || !organizationId) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="error">
                    Please log in to access this page.
                </Alert>
            </Box>
        );
    }

    return (
        <SixthStepErrorBoundary>
            <Stepper activeStep={6} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper
                elevation={5}
                sx={{
                    padding: { xs: '16px', sm: '24px', md: '32px' },
                    margin: '32px',
                    borderRadius: '12px',
                }}
            >
                <Box>
                    <Typography variant='h4'>Terms of Service</Typography>
                    <Typography variant='h5'>
                        Please provide the same terms and policies that you had provided to your payment processor.
                    </Typography>
                </Box>

                <Divider sx={{ my: 3 }} />

                {error && (
                    <Alert severity='error' sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <Box sx={{ mb: 4 }}>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        label='Terms of Service'
                        value={tosText}
                        onChange={e => setTosText(e.target.value)}
                        error={!!error && !tosText.trim()}
                        helperText={error && !tosText.trim() ? error : ''}
                        disabled={loading}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-end' } }}>
                    <Button variant="cancel" onClick={handleCancel} disabled={loading}>
                        Previous
                    </Button>
                    <Button
                        variant="save"
                        onClick={handleConfirm}
                        disabled={loading || !tosText.trim()}
                        sx={{
                            textTransform: 'none',
                            height: '34px',
                            '&.Mui-disabled': {
                                color: 'white'
                            }
                        }}
                    >
                        Next
                    </Button>
                </Box>
            </Paper>
        </SixthStepErrorBoundary>
    );
};

export default React.memo(SixthStep);
