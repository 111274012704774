import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Container, FormControl, InputLabel, Select, MenuItem, Alert, Autocomplete } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { CompanyLogo } from '../header/HeaderSvgImages';
import { registerAgent } from '../../api/auth';
import axios from 'axios';
import { authService } from '../../services/auth.service';

const AgentRegistration = () => {
  const theme = createTheme();
  const navigate = useNavigate();
  const [step, setStep] = useState('email'); // 'email', 'login', or 'form'
  const [formData, setFormData] = useState({
    type: 'A',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    organizationId: null
  });
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [tempToken, setTempToken] = useState(null);
  const [existingUser, setExistingUser] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear errors when user types
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleOrganizationChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      organizationId: newValue?.id || null
    }));
  };

  const getTemporaryToken = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/temp-token-for-registration`);
      return response.data.data.token;
    } catch (error) {
      console.error('Error getting temporary token:', error);
      throw new Error('Failed to get temporary access token');
    }
  };

  const checkUserExists = async (email) => {
    try {
      // Get temporary token first
      const tempToken = await getTemporaryToken();
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/check-user?email=${encodeURIComponent(email)}`,
        {
          headers: {
            'Authorization': `Bearer ${tempToken}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error checking user:', error);
      throw new Error('Failed to check user status');
    }
  };

  const handleEmailCheck = async (email) => {
    if (!email) {
      setErrors({ email: 'Email is required' });
      return;
    }

    setLoading(true);
    try {
      const { exists, userData } = await checkUserExists(email);
      
      if (exists) {
        setExistingUser(userData);
        setFormData(prev => ({
          ...prev,
          firstName: userData.firstName || '',
          lastName: userData.lastName || ''
        }));
        setStep('login');
      } else {
        setStep('form');
      }
    } catch (error) {
      setErrors({ email: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      const token = await authService.login(formData.email, formData.password);
      if (token) {
        // After successful login, proceed with agent registration
        const response = await registerAgent({
          email: formData.email,
          organizationId: formData.organizationId,
          type: formData.type || 'A', // Ensure type is always sent
          existingUser: true,
          firstName: formData.firstName,
          lastName: formData.lastName
        });

        if (response.success) {
          navigate('/agent-onboarding');
        } else {
          setErrors({ submit: response.error?.message || 'Registration failed' });
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrors({ 
        submit: error.response?.data?.error || error.message || 'Invalid email or password'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      // Email check if not done yet
      if (step === 'email') {
        await handleEmailCheck(formData.email);
        return;
      }

      // For existing users, handle login
      if (existingUser) {
        await handleLogin(e);
        return;
      }

      // Validation for new users
      const validationErrors = {};
      
      if (!formData.organizationId) {
        validationErrors.organizationId = 'Organization is required';
      }

      if (!formData.firstName) validationErrors.firstName = 'First name is required';
      if (!formData.lastName) validationErrors.lastName = 'Last name is required';
      if (!formData.password) validationErrors.password = 'Password is required';
      if (formData.password !== formData.confirmPassword) {
        validationErrors.confirmPassword = 'Passwords do not match';
      }

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setLoading(false);
        return;
      }

      const response = await registerAgent({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        type: formData.type || 'A',
        organizationId: formData.organizationId,
        existingUser: false
      });

      if (response.success) {
        // For new users, login first then redirect
        await authService.login(formData.email, formData.password);
        navigate('/agent-dashboard');
      } else {
        setErrors({ 
          submit: response.error?.message || 'Registration failed'
        });
      }
    } catch (error) {
      console.error('Registration error:', error);
      setErrors({ 
        submit: error.response?.data?.error || error.message || 'Registration failed'
      });
    } finally {
      setLoading(false);
    }
  };

  const renderForm = () => {
    if (step === 'email') {
      return (
        <>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            sx={{ mb: 2 }}
          />
        </>
      );
    }

    if (step === 'login') {
      return (
        <>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            disabled
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
            sx={{ mb: 2 }}
          />

          <Autocomplete
            options={organizations}
            getOptionLabel={(option) => option.name || ''}
            value={organizations.find(org => org.id === formData.organizationId) || null}
            onChange={handleOrganizationChange}
            onInputChange={(event, newInputValue) => {
              searchOrganizations(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Organization"
                error={!!errors.organizationId}
                helperText={errors.organizationId}
                sx={{ mb: 2 }}
              />
            )}
            fullWidth
          />
        </>
      );
    }

    return (
      <>
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          disabled
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          error={!!errors.firstName}
          helperText={errors.firstName}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          error={!!errors.lastName}
          helperText={errors.lastName}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          sx={{ mb: 2 }}
        />

        <Autocomplete
          options={organizations}
          getOptionLabel={(option) => option.name || ''}
          value={organizations.find(org => org.id === formData.organizationId) || null}
          onChange={handleOrganizationChange}
          onInputChange={(event, newInputValue) => {
            searchOrganizations(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Organization"
              error={!!errors.organizationId}
              helperText={errors.organizationId}
              sx={{ mb: 2 }}
            />
          )}
          fullWidth
        />
      </>
    );
  };

  const searchOrganizations = async (searchQuery) => {
    try {
      // Get or refresh temporary token
      if (!tempToken) {
        const newToken = await getTemporaryToken();
        setTempToken(newToken);
      }

      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/public-organizations?search=${searchQuery}`, {
        headers: {
          'Authorization': `Bearer ${tempToken}`
        }
      });
      setOrganizations(response.data.data.organizations);
    } catch (error) {
      if (error.response?.status === 401) {
        // Token might be expired, try to get a new one
        const newToken = await getTemporaryToken();
        setTempToken(newToken);
        if (newToken) {
          // Retry the search with new token
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/public-organizations?search=${searchQuery}`, {
            headers: {
              'Authorization': `Bearer ${newToken}`
            }
          });
          setOrganizations(response.data.data.organizations);
        }
      } else {
        console.error('Error fetching organizations:', error);
        setErrors(prev => ({
          ...prev,
          organizationId: 'Failed to fetch organizations. Please try again.'
        }));
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        bgcolor: 'background.default',
        padding: 0,
        margin: 0
      }}>
        <Container maxWidth="100%" sx={{ padding: 0, margin: 0 }}>
          <Box sx={{
            display: 'flex',
            height: '100vh',
            width: '100%',
            '& > *': {
              flex: '0 0 50%!important',
              width: '50%!important',
              maxWidth: '50%!important',
              height: '100vh',
              boxSizing: 'border-box',
              overflowY: 'auto'
            }
          }}>
            {/* Form Section */}
            <Box sx={{
              p: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%!important',
              height: '100%!important',
              boxSizing: 'border-box',
              flex: '0 0 50%!important'
            }}>
              <Box sx={{ mb: 3, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src="/fantet-logo.png" alt="Fantet Logo" style={{ height: '50px' }} />
              </Box>
              <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                {step === 'login' ? 'Login to Continue' : 'Welcome Agent!'}
              </Typography>
              <Typography color="textSecondary" gutterBottom sx={{ mb: 4 }}>
                {step === 'login' 
                  ? 'Please login to register as an agent'
                  : 'Sign up as an agent to start managing events and venues.'}
              </Typography>

              {errors.submit && (
                <Alert severity="error" sx={{ mb: 4 }}>
                  {errors.submit}
                </Alert>
              )}
              
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <input type="hidden" name="type" value="A" />

                {renderForm()}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: '#ffed00',
                    color: 'black',
                    '&:hover': {
                      bgcolor: '#ffed00',
                    },
                    mb: 2,
                  }}
                  disabled={loading}
                >
                  {loading 
                    ? 'Processing...' 
                    : (step === 'email' 
                        ? 'Continue' 
                        : step === 'login' 
                          ? 'Login & Register' 
                          : 'Sign Up')}
                </Button>

                {step !== 'email' && (
                  <Button
                    fullWidth
                    variant="text"
                    onClick={() => {
                      setStep('email');
                      setExistingUser(null);
                      setErrors({});
                      setFormData(prev => ({
                        ...prev,
                        password: '',
                        confirmPassword: '',
                        organizationId: null
                      }));
                    }}
                    sx={{ mb: 2 }}
                  >
                    Change Email
                  </Button>
                )}

                <Box sx={{ textAlign: 'center' }}>
                  <Typography color="textSecondary">
                    {step !== 'login' && (
                      <>
                        Already have an account?{' '}
                        <Link to="/login" style={{ color: '#ffb800', textDecoration: 'none' }}>
                          Sign In
                        </Link>
                      </>
                    )}
                  </Typography>
                </Box>
              </form>
            </Box>

            {/* Image Section */}
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                bgcolor: '#f5f5f5',
                backgroundImage: 'url("/bg.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100%',
                boxSizing: 'border-box',
                position: 'relative'
              }}
            >
              <Box sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
                <img src="/fantet-logo.png" alt="Fantet" style={{ width: '180px' }} />
              </Box>

              <Box sx={{ 
                color: 'white',
                position: 'absolute',
                bottom: 40,
                right: 40,
                textAlign: 'right',
                maxWidth: '500px',
                textShadow: '0 2px 4px rgba(0,0,0,0.5)',
                p: 2
              }}>
                <Typography variant="h4" gutterBottom fontWeight="bold">
                  Become An Agent Today!
                </Typography>
                <Typography variant="h4" gutterBottom fontWeight="bold">
                  Help Manage Events & Venues
                </Typography>
                <Typography>
                  Join our platform as an agent and help venue owners manage their spaces
                  and events more effectively.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AgentRegistration;
