import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { logger } from '../utils/logger';

const KEYCLOAK_URL = 'https://accounts.eventlah.com';
const REALM = 'eventlah';
const CLIENT_ID = 'ui-merchants-app';

// Define standard scopes
const DEFAULT_SCOPES = ['openid', 'profile', 'email', 'roles', 'offline_access'];

class AuthService {
    constructor() {
        this.navigate = null;
        this.isRefreshing = false;
        this.refreshSubscribers = [];
        // Initialize memory cache
        this._tokens = {
            access_token: null,
            refresh_token: null,
            token_expiry: null
        };
        // Initialize from localStorage
        this._initializeFromStorage();
        // Listen for storage events from other tabs
        window.addEventListener('storage', this._handleStorageChange.bind(this));
    }

    _initializeFromStorage() {
        this._tokens.access_token = localStorage.getItem('access_token');
        this._tokens.refresh_token = localStorage.getItem('refresh_token');
        this._tokens.token_expiry = localStorage.getItem('token_expiry');
    }

    // Handle storage events from other tabs
    _handleStorageChange(event) {
        if (event.key === 'access_token' || event.key === 'refresh_token' || event.key === 'token_expiry') {
            this._initializeFromStorage();
        }
    }

    // Method to set the navigation function
    setNavigate(navigateFunction) {
        this.navigate = navigateFunction;
    }

    // Method to handle redirect to login with loop prevention
    redirectToLogin() {
        const currentPath = window.location.pathname;
        if (currentPath === '/login') {
            return; // Prevent redirect loop
        }

        if (this.navigate) {
            this.navigate('/login', { replace: true });
        } else {
            window.location.replace('/login');
        }
    }

    isAuthenticated() {
        const accessToken = this.getAccessToken();
        const refreshToken = this.getRefreshToken();
        return !!(accessToken && refreshToken && !this.isTokenExpired());
    }

    async login(username, password) {
        try {
            const response = await axios.post(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    grant_type: 'password',
                    client_id: CLIENT_ID,
                    username,
                    password,
                    scope: DEFAULT_SCOPES.join(' ')
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            const { access_token, refresh_token, expires_in } = response.data;

            // Store tokens
            this._updateTokens(access_token, refresh_token, expires_in);

            // Remove startSilentRefresh call as it's now handled by Service Worker
            return response.data;
        } catch (error) {
            console.error('Login error:', error.response?.data || error.message);
            throw new Error(error.response?.data?.error_description || 'Login failed');
        }
    }

    async refreshToken() {
        const refresh_token = this.getRefreshToken();
        if (!refresh_token) {
            logger.warn('Refresh token attempt failed: No refresh token available');
            this.clearTokens();
            throw new Error('No refresh token available');
        }

        if (this.isRefreshing) {
            logger.debug('Token refresh already in progress, waiting for completion');
            return new Promise((resolve, reject) => {
                this.refreshSubscribers.push({ resolve, reject });
            });
        }

        this.isRefreshing = true;
        logger.info('Starting token refresh');

        try {
            const response = await axios.post(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    grant_type: 'refresh_token',
                    client_id: CLIENT_ID,
                    refresh_token: refresh_token,
                    scope: DEFAULT_SCOPES.join(' ')
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            const { access_token, refresh_token: new_refresh_token, expires_in } = response.data;

            // Use the new private method to update tokens
            this._updateTokens(access_token, new_refresh_token, expires_in);

            // Notify subscribers in the background
            setTimeout(() => {
                this.refreshSubscribers.forEach(subscriber => subscriber.resolve(response.data));
                this.refreshSubscribers = [];
            }, 0);

            logger.info('Token refresh successful', { 
                expiresIn: response.data.expires_in,
                tokenType: response.data.token_type 
            });

            return response.data;
        } catch (error) {
            logger.error('Token refresh failed', { 
                error: error.message,
                status: error.response?.status,
                statusText: error.response?.statusText
            });
            // Notify all subscribers of the failure
            setTimeout(() => {
                this.refreshSubscribers.forEach(subscriber => subscriber.reject(error));
                this.refreshSubscribers = [];
            }, 0);

            this.clearTokens();
            throw error;
        } finally {
            this.isRefreshing = false;
        }
    }

    async logout() {
        const refresh_token = this.getRefreshToken();

        try {
            if (refresh_token) {
                await axios.post(
                    `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/logout`,
                    new URLSearchParams({
                        client_id: CLIENT_ID,
                        refresh_token: refresh_token
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }
                );
            }
        } catch (error) {
            console.error('Logout error:', error.response?.data || error.message);
        } finally {
            this.clearTokens();
            this.redirectToLogin();
        }
    }

    // Update tokens in both memory and storage
    _updateTokens(access_token, refresh_token, expires_in) {
        const expiry = new Date().getTime() + (expires_in * 1000);
        
        // Update localStorage first to trigger storage event for other tabs
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('token_expiry', expiry);

        // Then update memory cache
        this._tokens.access_token = access_token;
        this._tokens.refresh_token = refresh_token;
        this._tokens.token_expiry = expiry;
    }

    clearTokens() {
        // Clear localStorage first
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expiry');
        
        // Then clear memory cache
        this._tokens.access_token = null;
        this._tokens.refresh_token = null;
        this._tokens.token_expiry = null;
    }

    // Get tokens from memory first, fallback to localStorage
    getAccessToken() {
        return this._tokens.access_token || localStorage.getItem('access_token');
    }

    getRefreshToken() {
        return this._tokens.refresh_token || localStorage.getItem('refresh_token');
    }

    getTokenExpiry() {
        return this._tokens.token_expiry || localStorage.getItem('token_expiry');
    }

    isTokenExpired() {
        const tokenExpiry = this.getTokenExpiry();
        if (!tokenExpiry) return true;

        // Add 30-second buffer to ensure we refresh before actual expiration
        return new Date().getTime() + 30000 > parseInt(tokenExpiry);
    }

    async ensureValidToken() {
        try {
            const accessToken = this.getAccessToken();
            const refreshToken = this.getRefreshToken();

            // If token exists and is not expired, return it immediately
            if (accessToken && !this.isTokenExpired()) {
                return accessToken;
            }

            // If token is expired but we have a refresh token, try to refresh
            if (refreshToken) {
                try {
                    const result = await this.refreshToken();
                    return result.access_token;
                } catch (error) {
                    console.error('Token refresh failed:', error);
                    this.clearTokens();
                    this.redirectToLogin();
                    return null;
                }
            }

            // If we get here, we need to redirect and clear tokens
            this.clearTokens();
            this.redirectToLogin();
            return null;
        } catch (error) {
            console.error('Auth validation error:', error);
            this.clearTokens();
            this.redirectToLogin();
            return null;
        }
    }

    async getUserInfo() {
        try {
            const token = await this.ensureValidToken();

            if (!token) {
                throw new Error('No valid token available');
            }

            const response = await axios.get(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/userinfo`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const decodedToken = jwtDecode(token);

            return {
                ...response.data,
                organization_roles: decodedToken.organization_roles || [],
                realm_roles: decodedToken.realm_access?.roles || [],
                resource_access: decodedToken.resource_access || {},
                organization_id: decodedToken.organization_id
            };
        } catch (error) {
            console.error('Error fetching user info:', error);
            if (error.response?.status === 401 || error.message === 'No valid token available') {
                this.clearTokens();
                this.redirectToLogin();
            }
            return null;
        }
    }
}

export const authService = new AuthService();

export const useAuthServiceNavigation = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        authService.setNavigate(navigate);
        return () => {
            // Clean up navigation on unmount
            authService.setNavigate(null);
        };
    }, [navigate]);

    return authService;
};
