import { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Checkbox,
    Button,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Divider,
    Alert,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useAgentOnboarding } from '../../../contexts/AgentOnboardingContext';
import AgentFirstStep from './AgentFirstStep';
import AgentSecondStep from './AgentSecondStep';
import AgentThirdStep from './AgentThirdStep';

const Section = ({ icon, title, content, onEdit, isComplete = true }) => (
    <Paper
        elevation={0}
        sx={{
            mb: 2.5,
            p: 2.5,
            borderRadius: 2,
            border: '1px solid',
            borderColor: isComplete ? '#E5E7EB' : '#ffeb3b',
            position: 'relative',
            '&:hover': {
                borderColor: '#ffeb3b',
                transition: 'all 0.2s ease-in-out'
            }
        }}
    >
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
        }}>
            <Box sx={{
                color: isComplete ? 'orange' : '#ffeb3b',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 1,
                borderRadius: 1,
                bgcolor: isComplete ? '#FFF9E6' : '#FFFDE7'
            }}>
                {icon}
            </Box>
            <Box sx={{ flex: 1 }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1.5
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        color: 'black',
                        fontSize: '16px'
                    }}>
                        {title}
                        {isComplete && (
                            <CheckCircleOutlineIcon
                                sx={{
                                    ml: 1,
                                    fontSize: 18,
                                    color: 'orange',
                                    verticalAlign: 'text-bottom'
                                }}
                            />
                        )}
                    </Typography>
                    <Button
                        startIcon={<EditOutlinedIcon />}
                        onClick={onEdit}
                        sx={{
                            color: 'orange',
                            textTransform: 'none',
                            fontWeight: 500,
                            '&:hover': {
                                bgcolor: '#FFFDE7'
                            }
                        }}
                    >
                        Edit
                    </Button>
                </Box>
                {content}
            </Box>
        </Box>
    </Paper>
);

const InfoGrid = ({ items }) => (
    <Box sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
        gap: 2,
        '& .label': {
            color: '#666666',
            fontSize: '14px',
            mb: 0.5
        },
        '& .value': {
            color: 'black',
            fontSize: '14px',
            fontWeight: 500
        }
    }}>
        {items.map((item, index) => (
            <Box key={index}>
                <Typography className="label">{item.label}</Typography>
                <Typography className="value">{item.value}</Typography>
            </Box>
        ))}
    </Box>
);

const AgentFourthStep = () => {
    const { agentData, isStepComplete } = useAgentOnboarding();
    const [openDialog, setOpenDialog] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);

    // Debug log to check the data
    console.log('AgentFourthStep - agentData:', agentData);

    const sections = [
        {
            icon: <PersonOutlineOutlinedIcon />,
            title: 'Personal Information',
            content: (
                <InfoGrid items={[
                    { 
                        label: 'Full Name', 
                        value: agentData.personalInfo ? 
                            `${agentData.personalInfo.firstName || ''} ${agentData.personalInfo.lastName || ''}`.trim() || 'Not provided' 
                            : 'Not provided'
                    },
                    { 
                        label: 'Email', 
                        value: agentData.personalInfo?.email || 'Not provided'
                    },
                    { 
                        label: 'Address', 
                        value: agentData.personalInfo ? 
                            `${agentData.personalInfo.addressLine1 || ''} ${agentData.personalInfo.addressLine2 ? `, ${agentData.personalInfo.addressLine2}` : ''}`.trim() || 'Not provided'
                            : 'Not provided'
                    },
                    { 
                        label: 'Postcode', 
                        value: agentData.personalInfo?.postcode || 'Not provided'
                    },
                    { 
                        label: 'State', 
                        value: agentData.personalInfo?.state || 'Not provided'
                    },
                    { 
                        label: 'Country', 
                        value: agentData.personalInfo?.country || 'Not provided'
                    }
                ]} />
            ),
            onEdit: () => setOpenDialog('personal'),
            isComplete: isStepComplete('step1')
        },
        {
            icon: <AccountBalanceOutlinedIcon />,
            title: 'Bank Information',
            content: (
                <InfoGrid items={[
                    { 
                        label: 'Bank Name', 
                        value: agentData.bankInfo?.bankName || 'Not provided'
                    },
                    { 
                        label: 'Account Number', 
                        value: agentData.bankInfo?.accountNumber || 'Not provided'
                    },
                    { 
                        label: 'Account Name', 
                        value: agentData.bankInfo?.accountName || 'Not provided'
                    }
                ]} />
            ),
            onEdit: () => setOpenDialog('bank'),
            isComplete: isStepComplete('step2')
        },
        {
            icon: <InsertDriveFileOutlinedIcon />,
            title: 'Documents',
            content: (
                <Box>
                    <Typography variant="body2" color="text.secondary">
                        {agentData.documents?.otherDocs?.length || 0} document(s) uploaded
                    </Typography>
                    {agentData.documents?.otherDocs?.length > 0 && (
                        <List dense>
                            {agentData.documents.otherDocs.map((doc, index) => (
                                <ListItem key={index}>
                                    <ListItemText 
                                        primary={doc.name || `Document ${index + 1}`}
                                        secondary={doc.type || 'Unknown type'}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            ),
            onEdit: () => setOpenDialog('documents'),
            isComplete: isStepComplete('step3')
        }
    ];

    const getDialogContent = () => {
        switch (openDialog) {
            case 'personal':
                return <AgentFirstStep />;
            case 'bank':
                return <AgentSecondStep />;
            case 'documents':
                return <AgentThirdStep />;
            default:
                return null;
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Review Your Information
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Please review all the information you've provided before proceeding.
            </Typography>

            {sections.map((section, index) => (
                <Section key={index} {...section} />
            ))}

            <Box sx={{ mt: 4 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                    }
                    label="I confirm that all the information provided is accurate and complete"
                />
            </Box>

            <Dialog
                open={Boolean(openDialog)}
                onClose={() => setOpenDialog('')}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Edit {openDialog.charAt(0).toUpperCase() + openDialog.slice(1)} Information
                </DialogTitle>
                <DialogContent>
                    {getDialogContent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog('')}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AgentFourthStep;