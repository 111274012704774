import React from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Info, Warning, Payment, Schedule } from '@mui/icons-material';

const CardValidationTerms = () => {
  return (
     <Box sx={{ p: 3, mt: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Important Terms & Conditions
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Card Validation Process
        </Typography>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <Payment fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="A validation charge of RM1 will be processed"
              secondary="This amount will be automatically refunded"
            />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Card Management Rules
        </Typography>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <Info fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="During active ticket sales, the validated card cannot be:"
              secondary="• Modified  • Deleted  • Edited"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Info fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Additional cards can be added during active sales"
            />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" color="error" gutterBottom>
          Card Change Requirements
        </Typography>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <Warning fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText 
              primary="To change the primary validated card:"
              secondary="1. Suspend ticket sales  2. Wait 48 hours cooling period  3. Process card change  4. Resume sales"
            />
          </ListItem>
        </List>
      </Box>

      <Box>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Fee Processing Information
        </Typography>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <Schedule fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Automatic fee processing every 2 days"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Warning fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Failed Payment Consequences:"
              secondary="• Immediate dashboard & email notifications  • 2-hour grace period  • Automatic sales suspension if payment remains unsuccessful"
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default CardValidationTerms;
