import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, Divider, styled, TextField, FormControl, InputAdornment, FormGroup, FormControlLabel, Checkbox, Switch, Autocomplete, Chip, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useGetSpacesQuery } from '../../../api-services/spaceApi';
import { fetchProducts } from '../../../../redux/productSlice';
import { fetchEvents } from '../../../../redux/eventsSlice';
import { authService } from '../../../../services/auth.service';

// Styled components remain the same...
const SettingRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': { backgroundColor: 'rgba(255, 235, 59, 0.04)' }
});

const SubSection = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    marginBottom: '16px'
});

const InlineFormGroup = styled(FormGroup)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
    '& .MuiFormControlLabel-root': {
        margin: 0,
        borderRadius: '6px',
        transition: 'all 0.2s',
        '&:hover': { backgroundColor: 'rgba(255, 235, 59, 0.08)' }
    },
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        fontWeight: 500
    }
});

const CategoryLabel = styled(FormControlLabel)({
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '16px',
    marginLeft: 3,
});

const LabelBox = styled(Box)({
    '& .title': {
        fontSize: '15px',
        fontWeight: 600,
        marginBottom: '2px'
    },
    '& .subtitle': {
        fontSize: '13px',
        color: 'rgba(0, 0, 0, 0.6)'
    }
});

const NestedSection = styled(Box)({
    marginBottom: '24px',
});

const InputContainer = styled(Box)({
    maxWidth: '280px',
    width: '100%',
});

const ItemsPreview = styled(Box)({
    marginTop: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    padding: '8px',
    borderRadius: '4px',
    minHeight: '32px',
    border: '1px solid rgba(255, 235, 59, 0.2)'
});

const StyledChip = styled(Chip)({
    height: '24px',
    fontSize: '12px',
    backgroundColor: 'rgba(255, 152, 0, 0.08)',
    borderColor: 'rgba(255, 152, 0, 0.3)',
    '&:hover': {
        backgroundColor: 'rgba(255, 152, 0, 0.12)'
    },
    '& .MuiChip-label': {
        padding: '0 8px',
    },
    '& .MuiChip-deleteIcon': {
        color: '#ff9800',
        fontSize: '16px',
        margin: '0 4px 0 -4px',
        '&:hover': {
            color: '#f57c00'
        }
    }
});

const DropdownBox = styled(Box)({
    marginTop: '8px',
    width: '100%',
    maxWidth: '280px',
    '& .MuiOutlinedInput-root': {
        height: '42px',
        '& .MuiAutocomplete-input': {
            padding: '0 !important',
            fontSize: '14px'
        }
    }
});

const CommissionSettings = () => {
    const dispatch = useDispatch();
    const [settings, setSettings] = useState({
        commissionType: 'percentage',
        commissionRate: '10',
        categories: {
            // allCategories: false,
            // products: { enabled: false, all: false, specific: false, selectedItems: [] },
            // booking: { enabled: false, all: false, specific: false, selectedItems: [] },
            ticketing: { enabled: false, all: false, specific: false, selectedItems: [] }
        }
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [itemsList, setItemsList] = useState({
        products: [],
        booking: [],
        ticketing: []
    });
    const [inputValues, setInputValues] = useState({
        products: '',
        booking: '',
        ticketing: ''
    });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [organizationId, setOrganizationId] = useState(null);

    const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const initializeSettings = async () => {
            try {
                const userInfo = await authService.getUserInfo();
                if (userInfo?.organization_id) {
                    setOrganizationId(userInfo.organization_id);
                } else {
                    throw new Error('Organization ID not found');
                }
            } catch (error) {
                console.error('Error getting user info:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to initialize settings: ' + error.message,
                    severity: 'error'
                });
                setLoading(false);
            }
        };

        initializeSettings();
    }, []);

    useEffect(() => {
        if (organizationId) {
            fetchCommissionSettings();
            // Fetch related data for dropdowns
            dispatch(fetchProducts());
            dispatch(fetchEvents());
        }
    }, [organizationId, dispatch]);

    useEffect(() => {
        const fetchEvents = async () => {
            if (organizationId && settings.categories.ticketing.specific) {
                try {
                    await authService.ensureValidToken();
                    const response = await fetch(`${BASE_API_URL}/events?organization_id=${organizationId}`, {
                        headers: {
                            'Authorization': `Bearer ${authService.getAccessToken()}`
                        }
                    });
                    
                    if (!response.ok) {
                        throw new Error('Failed to fetch events');
                    }

                    const data = await response.json();
                    if (data.data && data.data.events) {
                        // Map the events to the format expected by the Autocomplete component
                        const mappedEvents = data.data.events.map(event => ({
                            id: event.id,
                            name: event.name,
                            description: event.description,
                            event_type: event.event_type?.name || 'Unknown',
                            start_date: event.event_start_date,
                            end_date: event.event_end_date
                        }));
                        
                        setItemsList(prev => ({
                            ...prev,
                            ticketing: mappedEvents
                        }));
                    }
                } catch (error) {
                    console.error('Error fetching events:', error);
                    setSnackbar({
                        open: true,
                        message: 'Failed to load events',
                        severity: 'error'
                    });
                }
            }
        };

        fetchEvents();
    }, [organizationId, settings.categories.ticketing.specific, BASE_API_URL]);

    const fetchCommissionSettings = async () => {
        if (!organizationId) return;
        
        try {
            await authService.ensureValidToken();
            const response = await fetch(`${BASE_API_URL}/merchant-agent-settings?organization_id=${organizationId}`, {
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`
                }
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch commission settings');
            }

            const data = await response.json();
            if (data) {
                setSettings({
                    commissionType: data.commission_type || 'percentage',
                    commissionRate: data.base_commission_rate?.toString() || '10',
                    categories: {
                        allCategories: false,
                        products: {
                            enabled: data.commission_products?.length > 0 || false,
                            all: data.commission_products === '*',
                            specific: Array.isArray(data.commission_products),
                            selectedItems: Array.isArray(data.commission_products) ? data.commission_products : []
                        },
                        booking: {
                            enabled: data.commission_spaces?.length > 0 || false,
                            all: data.commission_spaces === '*',
                            specific: Array.isArray(data.commission_spaces),
                            selectedItems: Array.isArray(data.commission_spaces) ? data.commission_spaces : []
                        },
                        ticketing: {
                            enabled: data.commission_events?.length > 0 || false,
                            all: data.commission_events === '*',
                            specific: Array.isArray(data.commission_events),
                            selectedItems: Array.isArray(data.commission_events) ? data.commission_events : []
                        }
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching commission settings:', error);
            setSnackbar({
                open: true,
                message: 'Failed to load commission settings',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        if (!organizationId) {
            setSnackbar({
                open: true,
                message: 'Organization ID not found',
                severity: 'error'
            });
            return;
        }

        setSaving(true);
        try {
            await authService.ensureValidToken();
            const response = await fetch(`${BASE_API_URL}/merchant-agent-settings`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    organization_id: organizationId,
                    commission_type: settings.commissionType,
                    base_commission_rate: parseFloat(settings.commissionRate),
                    commission_products: settings.categories.products.all ? '*' : 
                        settings.categories.products.enabled ? 
                            (settings.categories.products.specific ? settings.categories.products.selectedItems : '*') : [],
                    commission_spaces: settings.categories.booking.all ? '*' : 
                        settings.categories.booking.enabled ? 
                            (settings.categories.booking.specific ? settings.categories.booking.selectedItems : '*') : [],
                    commission_events: settings.categories.ticketing.all ? '*' : 
                        settings.categories.ticketing.enabled ? 
                            (settings.categories.ticketing.specific ? settings.categories.ticketing.selectedItems : '*') : []
                })
            });

            if (!response.ok) {
                throw new Error('Failed to save commission settings');
            }

            setSnackbar({
                open: true,
                message: 'Commission settings saved successfully',
                severity: 'success'
            });
            setHasChanges(false);
        } catch (error) {
            console.error('Error saving commission settings:', error);
            setSnackbar({
                open: true,
                message: 'Failed to save commission settings: ' + error.message,
                severity: 'error'
            });
        } finally {
            setSaving(false);
        }
    };

    const updateSettings = (newSettings) => {
        setSettings(newSettings);
        setHasChanges(true);
    };

    const handleCommissionTypeChange = (type) => (event) => {
        if (event.target.checked) {
            updateSettings({ ...settings, commissionType: type });
        }
    };

    const handleCategorySwitch = (category) => (event) => {
        const newSettings = { ...settings };
        if (category === 'allCategories') {
            Object.keys(newSettings.categories).forEach(key => {
                if (key !== 'allCategories') {
                    newSettings.categories[key] = { enabled: false, all: false, specific: false, selectedItems: [] };
                }
            });
            newSettings.categories.allCategories = event.target.checked;
        } else {
            newSettings.categories.allCategories = false;
            newSettings.categories[category] = {
                ...newSettings.categories[category],
                enabled: event.target.checked,
                all: false,
                specific: false,
                selectedItems: []
            };
        }
        updateSettings(newSettings);
    };

    const handleSubCategoryChange = (category, subCategory) => (event) => {
        const newSettings = { ...settings };
        newSettings.categories[category] = {
            ...newSettings.categories[category],
            [subCategory]: event.target.checked,
            ...(subCategory === 'all' && event.target.checked ? { specific: false, selectedItems: [] } : {}),
            ...(subCategory === 'specific' && event.target.checked ? { all: false } : {})
        };
        updateSettings(newSettings);
    };

    const handleItemsChange = (category, newValue) => {
        updateSettings({
            ...settings,
            categories: {
                ...settings.categories,
                [category]: { ...settings.categories[category], selectedItems: newValue }
            }
        });
    };

    const renderCategorySection = (category) => {
        const categorySettings = settings.categories[category];
        const availableItems = itemsList[category] || [];

        // Filter out already selected items from available options
        const unselectedItems = availableItems.filter(
            item => !categorySettings.selectedItems.some(selected => selected.id === item.id)
        );

        return (
            <Box key={category}>
                <CategoryLabel
                    control={
                        <Switch
                            checked={categorySettings.enabled}
                            onChange={handleCategorySwitch(category)}
                            disabled={settings.categories.allCategories}
                        />
                    }
                    label={
                        <LabelBox>
                            <Typography className="title">{category.charAt(0).toUpperCase() + category.slice(1)}</Typography>
                            <Typography className="subtitle">Apply commission to {category}</Typography>
                        </LabelBox>
                    }
                />
                {categorySettings.enabled && (
                    <NestedSection>
                        <FormControlLabel
                            control={<Checkbox checked={categorySettings.all} onChange={handleSubCategoryChange(category, 'all')} />}
                            label={`All ${category}`}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={categorySettings.specific} onChange={handleSubCategoryChange(category, 'specific')} />}
                            label={`Specific ${category}`}
                        />
                        {categorySettings.specific && (
                            <>
                                <DropdownBox>
                                    <Autocomplete
                                        options={unselectedItems}
                                        getOptionLabel={(option) => {
                                            if (category === 'ticketing' && option) {
                                                return `${option.name} (${option.event_type}) - ${option.start_date} to ${option.end_date}`;
                                            }
                                            return option?.name || '';
                                        }}
                                        value={null}
                                        inputValue={inputValues[category]}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValues(prev => ({
                                                ...prev,
                                                [category]: newInputValue
                                            }));
                                        }}
                                        onChange={(_, newValue) => {
                                            if (newValue) {
                                                handleItemsChange(category, [...categorySettings.selectedItems, newValue]);
                                                // Clear the input after selection
                                                setInputValues(prev => ({
                                                    ...prev,
                                                    [category]: ''
                                                }));
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="large"
                                                placeholder={`Search ${category}...`}
                                            />
                                        )}
                                        renderOption={(props, option) => {
                                            if (category === 'ticketing') {
                                                return (
                                                    <li {...props}>
                                                        <Box>
                                                            <Typography variant="subtitle1">{option.name}</Typography>
                                                            <Typography variant="caption" color="text.secondary">
                                                                {option.event_type} • {option.start_date} to {option.end_date}
                                                            </Typography>
                                                        </Box>
                                                    </li>
                                                );
                                            }
                                            return <li {...props}>{option.name}</li>;
                                        }}
                                    />
                                </DropdownBox>
                                <ItemsPreview>
                                    {categorySettings.selectedItems.map((item) => (
                                        <StyledChip
                                            key={item.id}
                                            label={category === 'ticketing' ? 
                                                `${item.name} (${item.event_type})` : 
                                                item.name}
                                            size="small"
                                            variant="outlined"
                                            onDelete={() => {
                                                const updatedItems = categorySettings.selectedItems.filter(
                                                    selected => selected.id !== item.id
                                                );
                                                handleItemsChange(category, updatedItems);
                                            }}
                                        />
                                    ))}
                                </ItemsPreview>
                            </>
                        )}
                    </NestedSection>
                )}
            </Box>
        );
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Commission Settings</Typography>
                <Typography variant="body2" color="text.secondary">Define how agents earn their commission</Typography>
            </Box>

            <Divider />

            <SettingRow>
                <Box sx={{ width: '100%' }}>
                    <SubSection>Commission Type</SubSection>
                    <InlineFormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={settings.commissionType === 'percentage'} onChange={handleCommissionTypeChange('percentage')} />}
                            label="Percentage"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={settings.commissionType === 'fixed'} onChange={handleCommissionTypeChange('fixed')} />}
                            label="Fixed"
                        />
                    </InlineFormGroup>
                </Box>
            </SettingRow>

            <Box sx={{ my: 3 }}>
                <SubSection>Commission Rate</SubSection>
                <InputContainer>
                    <FormControl fullWidth>
                        <TextField
                            value={settings.commissionRate}
                            onChange={(e) => updateSettings({ ...settings, commissionRate: e.target.value })}
                            variant="outlined"
                            size="small"
                            type="number"
                            InputProps={{
                                endAdornment: settings.commissionType === 'percentage' ?
                                    <InputAdornment position="end">%</InputAdornment> :
                                    <InputAdornment position="start">RM</InputAdornment>,
                                inputProps: { min: 0, max: settings.commissionType === 'percentage' ? 100 : 999999 }
                            }}
                        />
                    </FormControl>
                </InputContainer>
            </Box>

            <Divider/>

            <SubSection>Select categories to apply commission</SubSection>
            {['ticketing'].map(category => renderCategorySection(category))}

            <Divider/>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={!hasChanges || saving}
                    startIcon={saving && <CircularProgress size={20} color="inherit" />}
                >
                    {saving ? 'Saving...' : 'Save Changes'}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default CommissionSettings;