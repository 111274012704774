export const agreements = [
    {
        title: 'Terms of Service',
        lastUpdated: '2/9/2024',
        content: [
            {
                header: 'Introduction',
                text: 'By accepting these Terms of Service, you agree to comply with all applicable laws and regulations regarding the use of our services.'
            },
            {
                header: 'Account Registration',
                text: 'You must provide accurate and complete information during the registration process and maintain the security of your account credentials.'
            },
            {
                header: 'Service Usage',
                text: 'You agree to use the service in compliance with all applicable laws and regulations and will not use it for any illegal or unauthorized purpose.'
            },
            {
                header: 'Data Protection',
                text: 'We are committed to protecting your personal information and will handle it in accordance with our Privacy Policy.'
            }
        ]
    },
    {
        title: 'Privacy Policy',
        lastUpdated: '2/9/2024',
        content: [
            {
                header: 'Information Collection',
                text: 'We collect information that you provide directly to us, including personal information and business documents.'
            },
            {
                header: 'Information Usage',
                text: 'We use the collected information to provide, maintain, and improve our services, and to ensure compliance with legal requirements.'
            },
            {
                header: 'Information Sharing',
                text: 'We do not share your personal information with third parties except as described in this Privacy Policy or with your consent.'
            },
            {
                header: 'Data Security',
                text: 'We implement appropriate security measures to protect your information from unauthorized access, disclosure, or destruction.'
            }
        ]
    }
];
