import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography, Grid } from '@mui/material';
import AgentList from './AgentList';
import CommissionsPerAgents from './CommissionsPerAgents';
import Revenues from './Revenues';
import Payout from './Payout';
import AnalyticsWidgetSummary from '../../shared/AnalyticsWidgetSummary';
import {
    Groups as AgentsIcon,
    Handshake as CommissionIcon,
    AccountBalance as RevenueIcon,
    Payments as PayoutIcon,
} from '@mui/icons-material';
import axios from 'axios';
import PermissionGate from 'components/shared/PermissionGate';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AgentsOverview = () => {
    const [value, setValue] = useState(0);
    const [totalAgents, setTotalAgents] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [agentsResponse, revenueResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/user-agents?count=true`),
                    axios.get(`${API_BASE_URL}/purchases?total_revenue=true`),
                ]);

                setTotalAgents(agentsResponse.data.data.count);
                setTotalRevenue(revenueResponse.data.data.totalRevenue);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex', padding: { xs: 1, md: '32px' } }}>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Agents Overview</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                            Comprehensive Insights into Your Team's Performance and Activity.
                        </Typography>
                    </Box>
                    <Box
                        sx={{ ml: 'auto', mr: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    ></Box>
                </Box>

                {/* Analytic Graphs */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='AGEN_READ' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Total Agents'
                                percent={2.8}
                                total={totalAgents}
                                icon={<AgentsIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [55, 58, 60, 52, 62, 58, 60, 64],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='AGEN_READ2' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Total Commission'
                                percent={4.5}
                                total={52420}
                                color='secondary'
                                icon={<CommissionIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [45000, 38000, 42000, 35000, 32000, 30000, 25000, 52420],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='AGEN_READ1' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Total Revenue'
                                percent={1.2}
                                total={totalRevenue}
                                color='warning'
                                icon={<RevenueIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [220000, 280000, 180000, 250000, 160000, 200000, 140000, 256000],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='AGEN_READ3' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Total Payouts'
                                percent={3.2}
                                total={85000}
                                color='error'
                                icon={<PayoutIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [65000, 72000, 58000, 78000, 62000, 75000, 60000, 85000],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                </Grid>

                <Box height='38px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label='service tabs'>
                        <Tab label='Agent List' />
                        <Tab label='Commissions per agents' />
                        <Tab label='Revenues' />
                        <Tab label='Payout' />
                    </Tabs>
                </Box>
                <Divider sx={{ margin: '10px 0 10px 0' }} />
                <Box>
                    {value === 0 && <AgentList />}
                    {value === 1 && <CommissionsPerAgents />}
                    {value === 2 && <Revenues />}
                    {value === 3 && <Payout />}
                </Box>
            </Box>
        </Box>
    );
};

export default AgentsOverview;
