import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Snackbar,
    Alert,
    Grid
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { authService } from '../../../services/auth.service';
import { useKeycloak } from '../../../contexts/KeycloakContext';
import { useAgentOnboarding } from '../../../contexts/AgentOnboardingContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const AgentFirstStep = forwardRef((props, ref) => {
    const { userInfo } = useKeycloak();
    const { agentData, updateStepData } = useAgentOnboarding();
    const [customerData, setCustomerData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        state: '',
        country: 'malaysia',
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [oldEmail, setOldEmail] = useState('');

    useEffect(() => {
        if (userInfo) {
            setCustomerData(prev => ({
                ...prev,
                firstName: userInfo.given_name || '',
                lastName: userInfo.family_name || '',
                email: userInfo.email || '',
            }));
            setOldEmail(userInfo.email || '');
        }
    }, [userInfo]);

    // Expose saveData method via ref
    useImperativeHandle(ref, () => ({
        saveData: async () => {
            console.log('saveData called in AgentFirstStep');
            console.log('Current customer data:', customerData);
            try {
                await handleSubmit();
                return true;
            } catch (error) {
                console.error('Error saving first step data:', error);
                return false;
            }
        }
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('Field changed:', { name, value });
        setCustomerData(prev => {
            const newData = {
                ...prev,
                [name]: value
            };
            console.log('Updated customer data:', newData);
            return newData;
        });
    };

    const validatePhoneNumber = (phoneNumber) => {
        // Basic phone number validation - can be adjusted based on your requirements
        const phoneRegex = /^\+?[\d\s-]{8,}$/;
        return phoneRegex.test(phoneNumber);
    };

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        
        setLoading(true);
        console.log('Submitting customer data:', customerData);
        
        try {
            const token = await authService.ensureValidToken();
            const userInfo = await authService.getUserInfo();
            
            // First update Keycloak profile if email, first name, or last name changed
            if (customerData.email !== oldEmail || 
                customerData.firstName !== userInfo.given_name || 
                customerData.lastName !== userInfo.family_name) {
                await authService.updateUserProfile({
                    firstName: customerData.firstName,
                    lastName: customerData.lastName,
                    email: customerData.email,
                });
                // Update the context with new user info
                await authService.updateUserInfo();
            }

            // Update the context with the form data first
            console.log('Updating context with data:', {
                personalInfo: {
                    firstName: customerData.firstName,
                    lastName: customerData.lastName,
                    email: customerData.email,
                    phoneNumber: customerData.phoneNumber,
                    addressLine1: customerData.addressLine1,
                    addressLine2: customerData.addressLine2,
                    postcode: customerData.postcode,
                    state: customerData.state,
                    country: customerData.country
                }
            });

            updateStepData('step1', {
                personalInfo: {
                    firstName: customerData.firstName,
                    lastName: customerData.lastName,
                    email: customerData.email,
                    phoneNumber: customerData.phoneNumber,
                    addressLine1: customerData.addressLine1,
                    addressLine2: customerData.addressLine2,
                    postcode: customerData.postcode,
                    state: customerData.state,
                    country: customerData.country
                }
            });

            if (!userInfo?.organization_id) {
                throw new Error('Organization ID not found. Please ensure you are part of an organization.');
            }

            // Then update the user agent data
            const response = await fetch(`${API_BASE_URL}/user-agents?upsert=true`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    first_name: customerData.firstName,
                    last_name: customerData.lastName,
                    email: customerData.email,
                    phone_number: customerData.phoneNumber,
                    address_line1: customerData.addressLine1,
                    address_line2: customerData.addressLine2,
                    postcode: customerData.postcode,
                    state: customerData.state,
                    country: customerData.country,
                    merchant_email: userInfo.email,
                    organization_id: parseInt(userInfo.organization_id)
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.description || "Failed to save agent information");
            }
            
            setSnackbarMessage(data.description || "Agent information saved successfully!");
            setSnackbarSeverity("success");
            
            // If email was changed, show additional message
            if (customerData.email !== oldEmail) {
                setTimeout(() => {
                    setSnackbarMessage("Your email has been updated. Please note that you will need to sign in again with your new email address.");
                    setSnackbarSeverity("info");
                    setSnackbarOpen(true);
                }, 3000);
            }

            return true;
        } catch (error) {
            console.error('Error saving agent information:', error);
            setSnackbarMessage(error.message || "An error occurred while saving agent information");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <Box>
                <Typography variant='h4'>Agents Personal Detail Form</Typography>
                <Typography variant='h5'>Enter your personal information.</Typography>
            </Box>

            <Divider/>

            <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                <Typography variant="body2">
                    Your first name, last name, and email are synchronized with your account information. 
                    Changing your email address will update your login credentials - you'll need to use the new email to sign in after saving.
                </Typography>
            </Alert>

            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            label="First Name"
                            name="firstName"
                            value={customerData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            value={customerData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={customerData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Phone Number"
                            name="phoneNumber"
                            value={customerData.phoneNumber}
                            onChange={handleChange}
                            helperText="Enter phone number with country code (e.g., +1234567890)"
                            error={customerData.phoneNumber && !validatePhoneNumber(customerData.phoneNumber)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Address Line 1"
                            name="addressLine1"
                            value={customerData.addressLine1}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Address Line 2"
                            name="addressLine2"
                            value={customerData.addressLine2}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Postcode"
                            name="postcode"
                            value={customerData.postcode}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="State"
                            name="state"
                            value={customerData.state}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Country</InputLabel>
                            <Select
                                name="country"
                                value={customerData.country}
                                onChange={handleChange}
                            >
                                <MenuItem value="malaysia">Malaysia</MenuItem>
                                <MenuItem value="singapore">Singapore</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </form>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
});

export default AgentFirstStep;