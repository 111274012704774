import {Box, TextField, Typography, Button, Divider, CircularProgress, Snackbar, Alert} from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { authService } from '../../../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AgentBankDetails = () => {
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(true);
    const [customerData, setCustomerData] = useState({
        bankName: '',
        accountNumber: '',
        accountName: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchData = async () => {
            await authService.ensureValidToken();
            fetchUserAgent();
        };
        fetchData();
    }, []);

    const fetchUserAgent = async () => {
        try {
            const token = authService.getAccessToken();
            if (!token) {
                throw new Error('No valid token available');
            }

            const response = await fetch(`${API_BASE_URL}/user-agents?id=1`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();

            if (result.code === 200 && result.data.userAgent) {
                const agent = result.data.userAgent;
                setCustomerData({
                    bankName: agent.bank_name || '',
                    accountNumber: agent.account_number || '',
                    accountName: agent.account_name || ''
                });
            }
        } catch (error) {
            console.error('Error fetching user agent:', error);
            setSnackbarMessage(error.message || "Failed to fetch user agent data");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setFetchLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await authService.ensureValidToken();
            const token = authService.getAccessToken();
            if (!token) {
                throw new Error('No valid token available');
            }

            const response = await fetch(`${API_BASE_URL}/user-agents?id=1`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    bank_name: customerData.bankName,
                    account_number: customerData.accountNumber,
                    account_name: customerData.accountName
                })
            });

            const result = await response.json();

            if (response.ok) {
                setSnackbarMessage("Bank details updated successfully");
                setSnackbarSeverity("success");
            } else {
                throw new Error(result.message || 'Failed to update bank details');
            }
        } catch (error) {
            console.error('Error updating bank details:', error);
            setSnackbarMessage(error.message || "Failed to update bank details");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    if (fetchLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box component="form" onSubmit={handleSubmit}>
            {/* Bank Details Fields */}
            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Bank Name</TextFieldTitle>
                <TextField
                    name="bankName"
                    value={customerData.bankName}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Bank Name"
                    label="Bank Name"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Account Number</TextFieldTitle>
                <TextField
                    name="accountNumber"
                    value={customerData.accountNumber}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Account Number"
                    label="Account Number"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Account Name</TextFieldTitle>
                <TextField
                    name="accountName"
                    value={customerData.accountName}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Account Name"
                    label="Account Name"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px' }}>
                <Button variant='cancel' type="button">Cancel</Button>
                <Button variant='save' type="submit" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </Box>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AgentBankDetails;
