import React, {useState} from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel, Tooltip, Alert } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import OnlinePayment from "../settings/others/Payment/PaymentMethod/OnlinePayment";
import {setCurrentStep, updateOnboardingRecord} from "../../redux/accountOnboardingSlice";
import {useDispatch} from "react-redux";
import { useKeycloak } from '../../contexts/KeycloakContext';
import EmailIcon from '@mui/icons-material/Email';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const SixthStep = () => {
    const [activeStep, setActiveStep] = useState(5);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
    const { userInfo } = useKeycloak();

    const handlePaymentMethodChange = (methods) => {
        // Handle both array and single object cases
        const paymentMethods = Array.isArray(methods) ? methods : [methods];
        
        // Check if any payment method is both active and has 'active' status
        const hasActiveMethod = paymentMethods.some(method => 
            method && method.is_active && method.status === 'active' && 
            method.merchant_id && method.secret_key // Ensure required fields are filled
        );
        setPaymentMethodSelected(hasActiveMethod);
    };

    const handleConfirm = async () => {
        try {
            const result = await dispatch(updateOnboardingRecord({
                email: userInfo?.email,
                onboardingData: {
                    current_step: 'seventh',
                    completed_steps: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth']
                }
            })).unwrap();
        } catch (error) {
            console.error('Failed to update server:', error);
        }
        dispatch(setCurrentStep('seventh'));
        navigate('/seventh');
    };

    const handleCancel = () => {
        dispatch(setCurrentStep('fifth'));
        navigate('/fifth');
    };

    const handleSkip = async () => {
        dispatch(setCurrentStep('seventh'));
        try {
            const result = await dispatch(updateOnboardingRecord({
                email: userInfo?.email,
                onboardingData: {
                    current_step: 'seventh',
                    completed_steps: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth']
                }
            })).unwrap();
            navigate('/seventh');
        } catch (error) {
            console.error('Failed to update server:', error);
        }
    };

    const handleContactSupport = () => {
        window.location.href = 'mailto:eventlah.adm@gmail.com?subject=Payment Processor Integration Request';
    };

    return (
        <>
            <Stepper activeStep={5} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box>
                    <Typography variant='h4'>
                        Payment Method
                    </Typography>
                    <Typography variant='h5'>
                        Select register with a payment processor of your choice and connect to use.
                    </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                <OnlinePayment onPaymentMethodChange={handlePaymentMethodChange} />

                <Box sx={{ mt: 3, mb: 3 }}>
                    <Alert 
                        severity="info" 
                        action={
                            <Button
                                color="info"
                                size="small"
                                startIcon={<EmailIcon />}
                                onClick={handleContactSupport}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 600
                                }}
                            >
                                Contact Us
                            </Button>
                        }
                    >
                        Want to integrate with a different payment processor? Contact us for support.
                    </Alert>
                </Box>

                <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-end' }, mt: 5 }}>
                    <Button
                        onClick={handleSkip}
                        sx={{
                            textTransform: 'none',
                            color: 'orange',
                            border: 'none',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                            },
                        }}
                    >
                        Skip for now
                    </Button>
                    <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                    <Tooltip title={!paymentMethodSelected ? "Please select and configure a payment method" : ""} placement="top">
                        <span>
                            <Button
                                variant="save"
                                onClick={handleConfirm}
                                disabled={!paymentMethodSelected}
                                sx={{
                                    textTransform: 'none',
                                    height: '34px',
                                    '&.Mui-disabled': {
                                        color: 'white'
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Paper>
        </>
    );
};

export default SixthStep;
