import { authService } from './auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const agentOnboardingService = {
    async getOnboardingStatus(email) {
        const token = await authService.ensureValidToken();
        const response = await fetch(`${API_BASE_URL}/agent-onboardings?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch onboarding status');
        }

        const data = await response.json();
        return data.data.onboardingRecord;
    },

    async updateOnboardingStep(userId, currentStep, completedSteps) {
        const token = await authService.ensureValidToken();
        const response = await fetch(`${API_BASE_URL}/agent-onboardings`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId,
                current_step: currentStep,
                completed_steps: completedSteps,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to update onboarding step');
        }

        const data = await response.json();
        return data.data.onboardingRecord;
    },

    async createOnboardingRecord(userId, currentStep = 'first', completedSteps = []) {
        const token = await authService.ensureValidToken();
        const response = await fetch(`${API_BASE_URL}/agent-onboardings`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId,
                current_step: currentStep,
                completed_steps: completedSteps,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create onboarding record');
        }

        const data = await response.json();
        return data.data.onboardingRecord;
    },
};
