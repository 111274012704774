import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Paper, Typography, TextField, Divider, Skeleton, Grid } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Add as AddIcon,
    Groups as TotalCustomersIcon,
    PersonAdd as NewCustomersIcon,
    Loop as RepeatCustomersIcon,
    TrendingUp as EngagementIcon,
} from '@mui/icons-material';
import { fetchCustomerDetails } from '../../../redux/customerDetailsSlice';
import AnalyticsWidgetSummary from '../../shared/AnalyticsWidgetSummary';
import PermissionGate from 'components/shared/PermissionGate';

const CustomerRecords = () => {
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const customerDetails = useSelector(state => state.customerDetails.customerDetails);
    const { pagination, status } = useSelector(state => state.customerDetails);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        dispatch(
            fetchCustomerDetails({
                page: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                searchQuery: searchQuery,
            })
        ).then(() => {
            setIsInitialLoad(false);
        });
    }, [paginationModel, searchQuery, dispatch]);

    useEffect(() => {
        if (pagination) {
            setTotalCount(pagination.totalCount || 0);
        }
    }, [pagination]);

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = newModel => {
        setPaginationModel(newModel);
    };

    const renderSkeletons = () => (
        <Box sx={{ padding: '0 32px 24px 32px' }}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant='rectangular' width={60} height={40} sx={{ mr: 5, borderRadius: '4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant='text' width='100%' />
                        <Skeleton variant='text' width='100%' />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const rows = useMemo(() => {
        return (
            customerDetails?.map(customer => ({
                ...customer,
                id: customer.id,
                fullName: `${customer.first_name} ${customer.last_name}`,
                email: customer.email,
                createdAt: new Date(customer.created_at).toLocaleDateString(),
            })) || []
        );
    }, [customerDetails]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.5,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'fullName',
            headerName: 'Full Name',
            flex: 1,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{params.value}</Typography>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Customers</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                            View, edit, and manage all your customers in one place.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            ml: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <PermissionGate featureCode='CREA2' fallback={null}>
                            <Button component={Link} to='/customers/add' variant='save' startIcon={<AddIcon />}>
                                Add New Customer
                            </Button>
                        </PermissionGate>
                    </Box>
                </Box>

                {/* Analytic Graphs */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='CUST_READ1' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Total Customers'
                                percent={2.8}
                                total={324}
                                icon={<TotalCustomersIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [280, 250, 310, 240, 320, 290, 300, 324],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='CUST_READ2' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='New Customers'
                                percent={4.5}
                                total={68}
                                color='secondary'
                                icon={<NewCustomersIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [45, 38, 42, 35, 32, 30, 25, 68],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='CUST_READ4' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Repeat Customers'
                                percent={1.2}
                                total={256}
                                color='warning'
                                icon={<RepeatCustomersIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [220, 280, 180, 250, 160, 200, 140, 256],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='CUST_READ3' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Customer Engagement'
                                percent={3.2}
                                total={85}
                                color='error'
                                icon={<EngagementIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [65, 72, 58, 78, 62, 75, 60, 85],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                </Grid>

                <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                    <Box />
                    <Box display='flex' gap='12px'>
                        <TextField
                            variant='outlined'
                            placeholder='Search by Name or Email'
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size='small'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: '100%',
                        borderRadius: '16px',
                        marginTop: '24px',
                        border: '1px solid var(--Gray-200, #EAECF0)',
                        background: 'var(--White, #FFF)',
                        boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '20px 24px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Gray-900, #101828)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '28px',
                            }}
                        >
                            All Customers
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 0 }} />

                    {isInitialLoad || status === 'loading' ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            rowCount={totalCount}
                            paginationMode='server'
                            disableRowSelectionOnClick
                            loading={status === 'loading'}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default CustomerRecords;
