import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Snackbar,
    Alert,
    CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { authService } from '../../../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AgentsSetupForm = () => {
    const [customerData, setCustomerData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        state: '',
        country: 'malaysia',
        bankName: '',
        accountNumber: '',
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await authService.ensureValidToken();
            fetchUserAgent();
        };
        fetchData();
    }, []);

    const fetchUserAgent = async () => {
        try {
            const token = authService.getAccessToken();
            if (!token) {
                throw new Error('No valid token available');
            }

            const response = await fetch(`${API_BASE_URL}/user-agents?id=1`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();

            if (result.code === 200 && result.data.userAgent) {
                const agent = result.data.userAgent;
                setCustomerData({
                    addressLine1: agent.address_line_1 || '',
                    addressLine2: agent.address_line_2 || '',
                    postcode: agent.postcode || '',
                    state: agent.state || '',
                    country: agent.country?.toLowerCase() || 'malaysia',
                    bankName: agent.bank_name || '',
                    accountNumber: agent.account_number || '',
                });
            }
        } catch (error) {
            console.error('Error fetching user agent:', error);
            setSnackbarMessage(error.message || "Failed to fetch user agent data");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setFetchLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await authService.ensureValidToken();
            const token = authService.getAccessToken();
            if (!token) {
                throw new Error('No valid token available');
            }

            const response = await fetch(`${API_BASE_URL}/user-agents?id=1`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    address_line_1: customerData.addressLine1,
                    address_line_2: customerData.addressLine2,
                    postcode: customerData.postcode,
                    state: customerData.state,
                    country: customerData.country.toUpperCase(),
                    bank_name: customerData.bankName,
                    account_number: customerData.accountNumber,
                })
            });

            const result = await response.json();

            if (response.ok) {
                setSnackbarMessage("Profile updated successfully");
                setSnackbarSeverity("success");
            } else {
                throw new Error(result.message || 'Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            setSnackbarMessage(error.message || "Failed to update profile");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (fetchLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {/* Address Field 1 */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Address Line 1</TextFieldTitle>
                    <TextField
                        name="addressLine1"
                        value={customerData.addressLine1}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Address Line 1'
                        label='Address Line 1'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Address Field 2 */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Address Line 2</TextFieldTitle>
                    <TextField
                        name="addressLine2"
                        value={customerData.addressLine2}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Address Line 2'
                        label='Address Line 2'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Postcode */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Postcode</TextFieldTitle>
                    <TextField
                        name="postcode"
                        value={customerData.postcode}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Postcode'
                        label='Postcode'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* State Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>State</TextFieldTitle>
                    <TextField
                        name="state"
                        value={customerData.state}
                        onChange={handleChange}
                        fullWidth
                        required
                        placeholder='State'
                        label='State'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Country Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Country</TextFieldTitle>
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <InputLabel>Country</InputLabel>
                        <Select
                            name="country"
                            value={customerData.country}
                            onChange={handleChange}
                            placeholder="Country"
                            label="Country"
                        >
                            <MenuItem value="malaysia">Malaysia</MenuItem>
                            <MenuItem value="singapore">Singapore</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px' }}>
                    <Button variant='cancel' type="button">Cancel</Button>
                    <Button variant='save' type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </Box>
            </form>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AgentsSetupForm;