import axios from 'axios';
import { authService } from '../../../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const handleError = (error, operation) => {
    console.error(`Error ${operation}:`, error);
    throw error;
};

// GET - Fetch all (with pagination and search)
export const fetchAgents = async (page, pageSize, searchQuery) => {
    try {
        // Ensure we have a valid token
        await authService.ensureValidToken();
        const token = authService.getAccessToken();

        const response = await axios.get(`${API_BASE_URL}/user-agents`, {
            params: {
                page: page + 1,
                limit: pageSize,
                search: searchQuery,
                byMerchant: true
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        handleError(error, 'fetching agents');
    }
};

// GET - Fetch users (with pagination and search)
export const fetchUsers = async (page, pageSize, searchQuery) => {
    try {
        // Ensure we have a valid token
        await authService.ensureValidToken();
        const token = authService.getAccessToken();

        const response = await axios.get(`${API_BASE_URL}/users`, {
            params: {
                page: page + 1,
                limit: pageSize,
                search: searchQuery
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        handleError(error, 'fetching users');
    }
};
