import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Box, Paper, Typography, Stepper, Step, StepLabel, Alert, Divider, CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { setCurrentStep, updateOnboardingRecord } from "../../redux/accountOnboardingSlice";
import { authService } from '../../services/auth.service';
import OnboardingPaymentMethod from './OnboardingPaymentMethod';
import CardValidationTerms from './CardValidationTerms';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from '../../utils/axios';

// Initialize Stripe with error handling
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!stripeKey) {
    console.error('Stripe public key is not set in environment variables');
}
const stripePromise = loadStripe(stripeKey);

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const FourthStep = () => {
    const [activeStep, setActiveStep] = useState(3);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stripeError, setStripeError] = useState(!stripeKey);
    const [loading, setLoading] = useState(true);
    const [existingPaymentMethod, setExistingPaymentMethod] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkExistingPaymentMethod = async () => {
            try {
                const token = await authService.ensureValidToken();
                const { data } = await axios.get('/stripe/payment-methods', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                if (data && data.length > 0) {
                    setExistingPaymentMethod(data[0]);
                }
            } catch (err) {
                console.error('Error fetching payment methods:', err);
                setError('Failed to check existing payment methods. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        checkExistingPaymentMethod();
    }, []);

    const handlePaymentMethodAdded = async (paymentMethodId) => {
        try {
            const userInfo = await authService.getUserInfo();
            const result = await dispatch(updateOnboardingRecord({
                email: userInfo.email,
                onboardingData: {
                    current_step: 'fifth',
                    completed_steps: ['first', 'second', 'third', 'fourth']
                }
            })).unwrap();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(setCurrentStep('fifth'));
            navigate('/fifth');
        } catch (error) {
            console.error('Failed to update onboarding status:', error);
            setError('Failed to update onboarding status. Please try again.');
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Stepper activeStep={3} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', mb: 3 }}>
                    <Typography variant='h4'>
                        Payment Method Setup
                    </Typography>
                    <Typography variant='h5'>
                        {existingPaymentMethod ? 'Payment method is ready' : 'Add your payment method to continue.'}
                    </Typography>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {stripeError ? (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        Unable to load payment system. Please contact support.
                    </Alert>
                ) : (
                    <>
                        <Alert severity="info" sx={{ mb: 3, fontSize: '0.875rem', '& .MuiAlert-message': { width: '100%' } }}>
                            {existingPaymentMethod 
                                ? 'Your payment method has been verified and is ready for use.'
                                : 'Please add a valid payment method to proceed. A validation charge of RM1 will be processed and automatically refunded.'}
                        </Alert>

                        <Elements stripe={stripePromise}>
                            <OnboardingPaymentMethod 
                                onPaymentMethodAdded={handlePaymentMethodAdded}
                                existingPaymentMethod={existingPaymentMethod}
                            />
                        </Elements>

                        <Divider sx={{ my: 4 }} />
                        
                        <CardValidationTerms />
                    </>
                )}
            </Paper>
        </>
    );
};

export default FourthStep;
