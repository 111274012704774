import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import AddTicketingForm from "../AddTicketingForm";
import AddPackageToEvent from "../AddPackageToEvent";
import { useLocation } from "react-router-dom";
import AddEventImages from "../AddEventImages";
import DailyEvents from "../DailyEvents";

const AddEvent = () => {
    const [value, setValue] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const location = useLocation();
    const event = location.state?.event;

    useEffect(() => {
        // Set edit mode based on whether we have event data
        setIsEditMode(!!event);
    }, [event]);

    //resetting the value state to 0 when there's no event
    useEffect(() => {
        if (!event) {
            setValue(0);
        }
    }, [event]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEventCreated = (newEvent) => {
        setEvent(newEvent);
        setIsEditMode(true);
        setValue(1);  // Optionally move to the next tab after creation
    };

    return (
        <Box flex={1}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%' }}>
                <Box>
                    <Typography variant='h4'>
                        {isEditMode ? 'Edit Event' : 'Add New Event'}
                    </Typography>
                    <Typography variant='h5'>
                        {isEditMode
                            ? 'Modify your existing event details, packages, and settings.'
                            : 'Fill out the details below to add a new event, customize event types, and set event availability.'}
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    {/*for buttons*/}
                </Box>
            </Box>

            <Box height='38px' paddingTop='32px'>
                <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                    <Tab label={isEditMode ? "Edit Event" : "Add New Event"} />
                    <Tab label="Add Packages to Event" disabled={!event} />
                    <Tab label="Add Event Image" disabled={!event} />
                    <Tab label="Scheduled Events" disabled={!event} />
                </Tabs>
            </Box>

            <Divider sx={{ margin: '41px 0 32px 0' }} />

            <Box>
                {value === 0 && <AddTicketingForm editEvent={event} />}
                {value === 1 && <AddPackageToEvent eventId={event?.id}  />}
                {value === 2 && <AddEventImages eventId={event?.id} />}
                {value === 3 && <DailyEvents eventId={event?.id} />}
            </Box>
        </Box>
    );
};

export default AddEvent;
